import { useParams } from "react-router";
import { Container } from "../../../components";
import { AmslerTest } from "./AmslerTest";
import { PatientFormService } from "../../../services";
import { dataURItoBlob } from "../../../utils";

export const Amsler = ({
  variableId,
  getNextStep,
  startPageProps,
  resetAmslerLocalStep,
  loadPatientFormId,
}: any) => {
  const { patientFormId } = useParams();

  const onSelectResponse = async (answer: any) => {
    if (answer && answer.current && patientFormId) {
      const amslerGridImage =
        answer.current.canvas.drawing.toDataURL("image/png");

      var bodyFormData = new FormData();
      var blob = dataURItoBlob(amslerGridImage);
      bodyFormData.append("grid", blob);

      const { data } = await PatientFormService.postAmslerGrid(
        patientFormId,
        variableId,
        bodyFormData
      );

      loadPatientFormId(data.PatientFormId);
      resetAmslerLocalStep();
      getNextStep();
    }
  };

  return (
    <Container>
      <h1 className="text-center">ŒIL {startPageProps.eye}</h1>
      <AmslerTest onSelectResponse={onSelectResponse} />
    </Container>
  );
};
