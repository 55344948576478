import { Container } from "../../../components";

export const FormQualityOfLifeHorusEnd = ({
                                              topRef,
                                              currentStep,
                                              previousStep,
                                              formVariablesLength,
                                              handleNext
                                          }: {
    topRef: any;
    currentStep: number;
    previousStep: () => void;
    formVariablesLength: number;
    handleNext: () => void;
}) => (
    <Container>

        <progress
            ref={topRef}
            max="100"
            value={(currentStep / formVariablesLength) * 100}
        />

        <u className="text-primary" onClick={() => previousStep()}>
            Poprzednia strona
        </u>

        <h1 className="text-center">
            Ukończyłeś wypełnianie kwestionariusza
        </h1>

        <p className="text-center">
            Jeśli chcesz zmienić swoje odpowiedzi, kliknij „Poprzednia strona”.
        </p>
        <p className="text-center">
            Jeśli jesteś zadowolony z udzielonych odpowiedzi, kliknij „Zakończ”.
        </p>

        <button
            className="mt-auto"
            onClick={() => {
                handleNext();
            }}
        >
            Ukończony
        </button>
    </Container>
);
