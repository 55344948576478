export const sessionStorageKey = {
    contrastPerceptionRight: 'CONTRAST_PERCEPTION_RIGHT',
    contrastPerceptionLeft: 'CONTRAST_PERCEPTION_LEFT',
    visualAcuityRight: 'VISUAL_ACUITY_RIGHT',
    visualAcuityLeft: 'VISUAL_ACUITY_LEFT',
    visualAcuityBoth: 'VISUAL_ACUITY_BOTH',
    zoomSetting: 'ZOOM_SETTING',
    amslerGridRight: 'AMSLER_GRID_RIGHT',
    amslerGridLeft: 'AMSLER_GRID_LEFT',
    lifeQuality: 'LIFE_QUALITY',
    imageWidth: 'IMAGE_WIDTH'
  };
  