import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import securityIcon from "../../assets/images/svg/Security.svg";
import { colors } from "../../constants";
import { useParams } from "react-router";
import useAuth from "../../hooks/useAuth/useAuth";
import { Container } from "../../components";

const HeaderWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: "2rem",

  img: {
    marginRight: "1rem",
  },

  span: {
    fontFamily: "Luciole Bold",
    color: colors.BLUE,
    fontSize: "2rem",
  },
});

const Header = (): JSX.Element => {
  return (
    <HeaderWrapper>
      <img
        src={securityIcon}
        alt="logo-security"
        style={{
          width: "3rem",
        }}
      />
      <span className="theme-blue">
        Connexion à <br /> Curecall
      </span>
    </HeaderWrapper>
  );
};


const AutoLogin = () => {
  const { patientId, patientFormId,hash } = useParams();
  const [error, setError] = useState<any>({});
  const { autologin } = useAuth();
    const [autologinInProgress, setAutologinInProgress] = useState<boolean>(false);

    useEffect(() => {
        console.log('autologinInProgress---->',autologinInProgress)
        async function fetchAutologin() {
            return autologin && !autologinInProgress ? autologin({patientId, patientFormId, hash}) : ""
        }
        if(!autologinInProgress) {
            setAutologinInProgress(true)
            fetchAutologin().then(r => console.log()).catch((e)=>{
                setError({
                    connexion_error:
                        " Vos informations de connexion sont invalides",
                });
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autologinInProgress,hash,patientFormId,patientId]);

  return (
    <Container>
      <Header />
        {error && error.connexion_error && (
          <div style={{ color: "red" }}>
            <p> {error.connexion_error} </p>
          </div>
        )}
    </Container>
  );
};

export default AutoLogin;
