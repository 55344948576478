import { useEffect, useState } from "react";
import {
  FormQualityOfLifeNeiStart,
  FormQualityOfLifeNeiEnd,
  QualityOfLifeNEISingleQuestion,
} from "../../../components";
import { DynamicFormProps } from "../types/dynamic-form";
import { PatientFormService } from "../../../services";

export const QualityOfLifeNEIDynamicForm = ({
  formVariables,
  patientVariables,
  currentStep,
  resumeStep,
}: DynamicFormProps) => {
  const [currentFormStep, setCurrentFormStep] = useState(
    resumeStep || currentStep
  );

  const [currentVariableUiIndex, setCurrentVariableUiIndex] =
    useState<number>(-1);
  const [currentVariableUi, setCurrentVariableUi] = useState(undefined);
  const [patientVariableIds, setPatientVariableIds] = useState<any>([]);
  const [qualityOfLifeVariables, setQualityOfLifeVariables] = useState<any>([]);

  const nextStep = () => {
    const lastVariable = patientVariableIds.length
      ? patientVariableIds[patientVariableIds.length - 1]
      : undefined;

    let currentStep = qualityOfLifeVariables.findIndex(
      (item: any) => item.Variable.id === lastVariable?.variableId
    );
    let nextStep = 0;
    if (currentStep !== -1) {
      nextStep = currentStep + 1;
    }
    const response = lastVariable?.patientAnswar;
    if (
      response &&
      qualityOfLifeVariables?.[currentStep]?.Variable?.settings?.connections
        ?.length
    ) {
      const connection = qualityOfLifeVariables?.[
        currentStep
      ]?.Variable?.settings?.connections.find(
        (item: any) => item.questionAnswer === response
      );
      if (connection) {
        const index = qualityOfLifeVariables.findIndex(
          (item: any) => item?.Variable?.name === connection.nextVariableName
        );
        if (index !== -1) {
          nextStep = index;
        }
      }
    }
    const uiLength =
      qualityOfLifeVariables[nextStep]?.Variable?.settings?.ui?.length;
    if (uiLength && uiLength > currentVariableUiIndex + 1) {
      setCurrentVariableUi(
        qualityOfLifeVariables[nextStep]?.Variable?.settings?.ui[
          currentVariableUiIndex + 1
        ]
      );
      setCurrentVariableUiIndex(currentVariableUiIndex + 1);
    } else {
      setCurrentVariableUi(undefined);
      setCurrentVariableUiIndex(-1);
    }
    setCurrentFormStep(nextStep);
  };

  const nextVariableStep = async (patientVariable: any) => {
    patientVariableIds.push({
      id: patientVariable.id,
      patientAnswar: patientVariable.patientAnswer,
      patientFormId: patientVariable.PatientFormId,
      variableId: patientVariable.VariableId,
    });
    setPatientVariableIds(patientVariableIds);
    nextStep();
  };

  const previousStep = async () => {
    if (patientVariableIds.length) {
      const lastVariable = patientVariableIds[patientVariableIds.length - 1];

      await PatientFormService.deletePatientVariable(
        lastVariable?.patientFormId,
        lastVariable.id
      );
      patientVariableIds.pop();
      setPatientVariableIds(patientVariableIds);
    }
    if (currentFormStep === 0 && currentVariableUiIndex !== -1) {
      setCurrentFormStep(-1);
      setCurrentVariableUi(undefined);
      setCurrentVariableUiIndex(-1);
    } else {
      nextStep();
    }
  };

  useEffect(() => {
    if (formVariables?.length) {
      setQualityOfLifeVariables(
        formVariables?.filter((item: any) => {
          return !item?.Variable?.settings?.isScoringVariable;
        })
      );
    }
  }, [formVariables]);

  useEffect(() => {
    if (patientVariables?.length) {
      setPatientVariableIds(
        patientVariables.map((item: any) => ({
          id: item.id,
          patientAnswar: item.patientAnswer,
          patientFormId: item.PatientFormId,
          variableId: item.VariableId,
        }))
      );
    } else {
    }
  }, [patientVariables]);

  useEffect(() => {
    if (patientVariableIds.length) {
      nextStep();
    }
  }, [patientVariableIds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {qualityOfLifeVariables.length && (
        <>
          {currentFormStep === -1 && (
            <FormQualityOfLifeNeiStart
              currentStep={currentFormStep}
              previousStep={previousStep}
              getNextStep={nextStep}
            />
          )}
          {currentVariableUi && (
            <FormQualityOfLifeNeiStart
              currentStep={currentFormStep}
              previousStep={previousStep}
              getNextStep={nextStep}
              uiMessage={currentVariableUi}
            />
          )}
          {currentFormStep !== -1 &&
            !currentVariableUi &&
            currentFormStep !== qualityOfLifeVariables.length && (
              <QualityOfLifeNEISingleQuestion
                formVariable={
                  qualityOfLifeVariables
                    ? qualityOfLifeVariables[currentFormStep]
                    : undefined
                }
                getNextStep={nextVariableStep}
                previousStep={previousStep}
                currentStep={currentFormStep}
                formVariablesLength={qualityOfLifeVariables.length}
              />
            )}
          {currentFormStep === qualityOfLifeVariables.length && (
            <FormQualityOfLifeNeiEnd />
          )}
        </>
      )}
    </>
  );
};
