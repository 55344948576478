import { CircularProgress } from "@material-ui/core";
import { Container } from "../..";

const CenteredLoader = (): JSX.Element => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "-webkit-fill-available",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    </Container>
  );
};

export default CenteredLoader;
