import { Container } from "../../../components";
import { PatientFormService } from "../../../services";
import { useParams } from "react-router";
import { MouseEvent } from "react";

export const SingleQuestion = ({
  formVariable,
  currentStep,
  getNextStep,
  formVariablesLength,
}: {
  formVariable: any;
  currentStep: number;
  formVariablesLength: number;
  getNextStep: () => void;
}) => {
  const { patientFormId } = useParams();

  const handleResponse = async (value: string) => {
    const data = {
      VariableId: formVariable?.Variable.id,
      VariableOptionId: 0,
      patientAnswer: value,
    };

    await PatientFormService.postValueToPatientFormByPatientFormId(
      patientFormId || "",
      data
    );

    getNextStep();
  };

  return (
    <Container>
      <progress max="100" value={(currentStep / formVariablesLength) * 100} />

      <h1>{formVariable?.Variable.label}</h1>

      {formVariable.Variable.type === "INTEGER_VALUE_RANGE" && (
        <section className="mt-auto flex flex-col gap-4">
          <button
            onClick={(e) => {
              handleResponse("0");
              e.currentTarget.blur();
            }}
          >
            OUI
          </button>
          <button
            onClick={(e) => {
              handleResponse("1");
              e.currentTarget.blur();
            }}
          >
            NON
          </button>
          <button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              handleResponse("2");
              e.currentTarget.blur();
            }}
            onMouseDown={(e) => e.currentTarget.blur()}
          >
            J’ai besoin d’un aidant
          </button>
        </section>
      )}

      {formVariable.Variable.type === "BOOLEAN" && (
        <section className="mt-auto flex flex-col gap-4">
          <button
            onClick={(e) => {
              handleResponse("0");
              e.currentTarget.blur();
            }}
          >
            OUI
          </button>
          <button
            onClick={(e) => {
              handleResponse("1");
              e.currentTarget.blur();
            }}
          >
            NON
          </button>
        </section>
      )}
    </Container>
  );
};
