import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_PATH
});

function isTokenExpiredError(errorResponse: { status: number }) {
  return errorResponse?.status === 401;
}

http.interceptors.request.use(
  (config: any) => {
    const token =
      localStorage.getItem("access_token") ??
      sessionStorage.getItem("access_token");
    if (token !== null) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorResponse = error.response;
    if (error.message === "Network Error") {
      return Promise.reject({
        ...error,
        ...{
          response: {
            data: {
              error_description: error.message,
            },
          },
        },
      });
    }
    if (
      isTokenExpiredError(errorResponse) &&
      window.location.pathname !== "/auth/login"
    ) {
      const patientId = localStorage.getItem("patientId") ?? undefined;
      const patientFormId = localStorage.getItem("patientFormId") ?? undefined;

      localStorage.clear();
      sessionStorage.clear();
      if (patientId)
        window.location.pathname = `/login/${patientId}/${patientFormId}`;
    }

    return Promise.reject(error);
  }
);

const setAuthToken = (token: string) => {
  if (token) {
    http.defaults.headers.common["Authorization"] = token;
  } else {
    delete http.defaults.headers.common["Authorization"];
  }
};

export { http, setAuthToken };
