import { Container } from "../components";
import logo from "../assets/images/svg/LogoCurecall.svg";

const PageNotFound = (): JSX.Element => {
  return (
    <Container className="text-center">
      <h1 className="p-4 bg-cyan-300 rounded-md">Bonjour</h1>

      <img
        src={logo}
        width="89"
        height="81"
        alt="logo-curecall"
        className="mx-auto"
      />

      <h2>La page que vous essayez de consulter n'est pas disponible.</h2>
    </Container>
  );
};

export default PageNotFound;
