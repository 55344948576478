import { useState } from "react";
import {
  AmslerFormStart,
  AmslerFormEnd,
  SetOfAmslerQuestions,
} from "../../../components";
import { DynamicFormProps } from "../types/dynamic-form";

export const AmslerDynamicForm = ({
  formVariables,
  currentStep,
  getNextStep,
}: DynamicFormProps) => {
  const [patientFormId, setPatientFormId] = useState<number | null>(null);

  const formVariablesLength = formVariables.length;

  const loadPatientFormId = (patientFormId: number) => {
    setPatientFormId(patientFormId);
  };

  return (
    <>
      {currentStep === -1 && <AmslerFormStart getNextStep={getNextStep} />}

      {currentStep !== -1 && currentStep !== formVariablesLength && (
        <SetOfAmslerQuestions
          variable={formVariables[currentStep]}
          getNextStep={getNextStep}
          loadPatientFormId={loadPatientFormId}
        />
      )}

      {currentStep === formVariablesLength && (
        <AmslerFormEnd patientFormId={patientFormId} />
      )}
    </>
  );
};
