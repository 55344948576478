export const VisualOpacityTest = ({
  item,
  onSelectResponse,
}: {
  item: any;
  onSelectResponse: (param: any) => any;
}) => {
  return (
    <>
      <h2 className="text-center">{item.target}</h2>

      <p className="text-center">Sélectionnez ce que vous voyez :</p>

      <section className="flex flex-col gap-4">
        {item.answerOptions.map((answer: string) => {
          const isDontKnow = answer === "Je ne sais pas";
          return isDontKnow ? (
            <button
              className="mt-auto"
              onClick={() => {
                onSelectResponse(answer);
              }}
            >
              {answer}
            </button>
          ) : (
            <button
              className="mt-auto"
              onClick={() => {
                onSelectResponse(answer);
              }}
            >
              {answer}
            </button>
          );
        })}
      </section>
    </>
  );
};
