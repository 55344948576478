import React, { useEffect, useState, createRef } from "react";
import securityIcon from "../../assets/images/svg/Security.svg";
import { useParams } from "react-router";
import useAuth from "../../hooks/useAuth/useAuth";
import { Container } from "../../components";

const dayRegex = /([1-9]|[12][0-9]|3[01])\b/;
const monthRegex = /^(01|02|03|04|05|06|07|08|09|10|11|12)$/;
const yearRegex = /\b(19|20)\d\d\b/;

const Login = () => {
  const { patientId, patientFormId } = useParams();

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState<any>({});
  const { login, redirectLoggedIn ,token } = useAuth();

  useEffect(() => {
    if(token) {
      const localPatientId = localStorage.getItem("patientId") ?? undefined;
      const localPatientFormId = localStorage.getItem("patientFormId") ?? undefined;
      if(localPatientFormId === patientFormId && localPatientId === patientId ) {
        if (patientFormId) redirectLoggedIn(patientFormId);
      }else {
        localStorage.clear();
        sessionStorage.clear();
      }
    }
  }, [redirectLoggedIn, patientFormId,patientId,token]);

  const onSaveToken = () => {
    setRemember((prev) => !prev);
  };

  const monthRef = createRef<HTMLInputElement>();
  const yearRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (day.length === 2 && dayRegex.test(day) && monthRef?.current) {
      monthRef.current.focus();
    }
    if (month.length === 2 && monthRegex.test(month) && yearRef?.current) {
      yearRef.current.focus();
    }
    if (year.length === 4 && yearRegex.test(year) && yearRef?.current) {
      yearRef.current.blur();
    }
  }, [day, monthRef, month, yearRef, year]);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      await login({ day, month, year, remember, patientId, patientFormId });
    } catch (err) {
      setError({
        connexion_error:
          " Vos informations de connexion sont invalides , veuillez réessayer",
      });
    }
  };

  return (
    <Container>
      <section className="flex flex-col items-center text-center gap-4">
        <img src={securityIcon} alt="logo-security" width="50" height="55" />
        <h1>
          Connexion à<br />
          Curecall
        </h1>
      </section>

      <form
        className="flex-grow flex flex-col text-center gap-8"
        data-testid="login-form"
        method="post"
      >
        <h2>
          Saisissez votre date <br />
          de naissance ci-dessous :
        </h2>

        <section className=" flex [&>input]:w-[110px] justify-center gap-2">
          <input
            maxLength={2}
            minLength={2}
            type="number"
            min="0"
            id="day-input"
            data-testid="day-input"
            value={day}
            placeholder="JJ"
            pattern="\d*"
            onChange={(event) => {
              if (event.target.value.length > 2) return;
              setDay(event.target.value);
            }}
          />

          <input
            ref={monthRef}
            maxLength={2}
            minLength={2}
            type="number"
            min="0"
            id="month-input"
            data-testid="month-input"
            value={month}
            pattern="\d*"
            placeholder="MM"
            onChange={(event) => {
              if (event.target.value.length > 2) return;
              setMonth(event.target.value);
            }}
          />

          <input
            ref={yearRef}
            maxLength={4}
            minLength={4}
            type="number"
            min="1900"
            id="year-input"
            data-testid="year-input"
            value={year}
            pattern="\d*"
            placeholder="AAAA"
            onChange={(event) => {
              if (event.target.value.length > 4) return;
              setYear(event.target.value);
            }}
          />
        </section>

        <div
          className="flex gap-2 justify-center items-end"
          onClick={onSaveToken}
        >
          <input type="checkbox" data-testid="remember" checked={remember} />
          <label>Se souvenir de moi</label>
        </div>

        {error && error.connexion_error && (
          <p className="text-red-500"> {error.connexion_error} </p>
        )}
        <button
          className="mt-auto"
          disabled={
            !dayRegex.test(day) ||
            !monthRegex.test(month) ||
            !yearRegex.test(year)
          }
          data-testid="submit-button"
          onClick={(event) => handleSubmit(event)}
        >
          CONSULTER
        </button>
      </form>
    </Container>
  );
};

export default Login;
