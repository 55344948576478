import ZoomSettingSample from "../../assets/images/svg/ZoomSettingSample.svg";
import ZoomMinus from "../../assets/images/svg/ZoomMinus.svg";
import ZoomPlus from "../../assets/images/svg/ZoomPlus.svg";

import { Container } from "../../components";

interface MeasureInstructionPageProps {
  getNextInstruction: () => void;
}

const MeasureInstructionPage = ({
  getNextInstruction,
}: MeasureInstructionPageProps) => {
  return (
    <Container>
      <h1 className="text-center">
        Réglons votre écran
        <br />
        pour le test
      </h1>

      <ol>
        <li>
          Nous allons vous afficher un rectangle qui doit correspondre à la
          largeur d’une carte vitale ou une carte bancaire.
        </li>

        <li>
          Vous allez prendre votre carte, la poser sur votre téléphone dans le
          rectangle et ajuster le rectangle à l’aide des boutons{" "}
          <div className="flex items-center">
            <img
              src={ZoomPlus}
              width="22"
              height="18"
              alt="zoom-plus-icon"
              style={{
                marginRight: "5px",
              }}
            />
            et
            <img
              className="rotate-[180deg]"
              src={ZoomMinus}
              width="22"
              height="18"
              alt="zoom-minus-icon"
              style={{ marginLeft: "5px" }}
            />
          </div>
        </li>

        <li>Voici un exemple :</li>
      </ol>

      <img
        src={ZoomSettingSample}
        style={{
          width: "12rem",
          height: "auto",
          marginBottom: "1rem",
        }}
        alt="zoom-setting-sample"
        className="mx-auto"
      />

      <button
        className="mt-auto"
        onClick={() => {
          getNextInstruction();
        }}
      >
        RÉGLER MON ÉCRAN
      </button>
    </Container>
  );
};

export default MeasureInstructionPage;
