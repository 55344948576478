import { Container } from "../../../components";
import { PatientFormService } from "../../../services";
import { useParams } from "react-router";
import { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import {FormQualityOfLifeHorusEnd} from "../qualityOfLifeHorus/QualityOfLifeHorusConfirmEnd";

export const DropsComfortHorusSingleQuestion = ({
  formVariable,
  currentStep,
  getNextStep,
  previousStep,
  formVariablesLength,
  locale,
}: {
  formVariable: any;
  currentStep: number;
  formVariablesLength: number;
  getNextStep: (response: any) => void;
  previousStep: () => void;
  locale: string;
}) => {
  const { patientFormId } = useParams();
  const topRef = useRef<HTMLProgressElement>(null);

  const [selectedResponse, setSelectedResponse] = useState<number>();

  const [displayConfirmationPage, setDisplayConfirmationPage] = useState(false);

  const handleNext = async () => {
    if(!displayConfirmationPage && formVariablesLength ===  (currentStep + 1)) {
      setDisplayConfirmationPage(true);
    }else {
      const variableOption =
          selectedResponse &&
          formVariable.Variable.VariableOptions.find(
              (item: any) => item.answerOptions[0] === selectedResponse
          );

      const body = {
        VariableId: formVariable?.Variable.id,
        patientAnswer: selectedResponse,
        VariableOptionId: variableOption.id,
        lastVariableOption: true,
      };
      try {
        const { data }: any =
            await PatientFormService.postValueToPatientFormByPatientFormId(
                patientFormId || "",
                body
            );
        getNextStep(data);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
      <>
        {displayConfirmationPage ? ( <FormQualityOfLifeHorusEnd currentStep={currentStep} formVariablesLength={formVariablesLength} previousStep={()=>{setDisplayConfirmationPage(false)}} handleNext={handleNext} topRef={topRef} />):
            (<Container>
              <progress
                  ref={topRef}
                  max="100"
                  value={(currentStep / formVariablesLength) * 100}
              />

              <u className="text-primary" onClick={() => previousStep()}>
                Poprzednia strona
              </u>

              <h1
                  dangerouslySetInnerHTML={{
                    __html:
                        formVariable?.Variable?.intlLabel?.[locale] ||
                        formVariable?.Variable.label,
                  }}
              />

              {formVariable.Variable.type === "OPTIONS" && (
                  <form className="gap-2 flex flex-col">
                    {formVariable.Variable.VariableOptions.map(
                        (variableOption: any, index: number) => {
                          const id = index + "-" + variableOption.id;
                          return (
                              <div
                                  key={index}
                                  className={twMerge(
                                      "bg-primary-100 rounded-xl py-4 px-3 cursor-pointer flex items-center",
                                      selectedResponse === variableOption.answerOptions[0] &&
                                      "bg-primary"
                                  )}
                                  onClick={() => {
                                    setSelectedResponse(variableOption.answerOptions[0]);
                                  }}
                              >
                                <input
                                    id={id}
                                    type="radio"
                                    name="try-an-answer"
                                    value={variableOption.answerOptions[0]}
                                    checked={
                                        selectedResponse === variableOption.answerOptions[0]
                                    }
                                />
                                <label
                                    htmlFor={id}
                                    className={twMerge(
                                        selectedResponse === variableOption.answerOptions[0]
                                            ? "text-white"
                                            : "text-black"
                                    )}
                                >
                                  {variableOption.answerOptions}
                                </label>
                              </div>
                          );
                        }
                    )}

                    <button
                        className="mt-4"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNext();
                        }}
                        disabled={!selectedResponse}
                    >
                      NASTĘPNY
                    </button>
                  </form>
              )}
            </Container>)}
      </>

  );
};
