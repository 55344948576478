import styled from "@emotion/styled";
import { Button as AntdButton } from "antd";
import { colors } from "../../../constants";

export const Button = styled(AntdButton)({
  padding: "1rem 1.5rem",
  maxWidth: "100%",
  backgroundColor: colors.BLUE,
  outline: "none",
  border: "none",
  boxShadow: "none",
  position: "relative",
  fontFamily: "Luciole Bold",
  span: {
    fontFamily: "Luciole Bold",
    fontWeight: 900,
    fontSize: "2rem",
    lineHeight: "25px",
    textAlign: "center",
    color: colors.WHITE,
    whiteSpace: "normal",
    maxWidth: "100%",
  },

  "&:disabled": {
    backgroundColor: colors.WHITE,
    border: `2px solid ${colors.BLUE}`,
    color: colors.BLUE,

    span: {
      color: colors.BLUE,
    },
  },

  "&.ant-btn-round": {
    borderRadius: 33,
  },

  "&:active, &:focus": {
    backgroundColor: colors.WHITE,
    border: `2px solid ${colors.BLUE}`,
    span: {
      color: colors.BLUE,
    },
  },
});

export const ButtonWithoutFocus = styled(Button)({
  "&:active, &:focus": {
    backgroundColor: colors.BLUE,
    border: "none",
    span: {
      color: colors.WHITE,
    },
  },
});
