import { useState } from "react";

import BlueHart from "../../../assets/images/svg/BlueHart.svg";

import { Container } from "../../../components";

export const FormQualityOfLifeHorusStart = ({
  currentStep,
  getNextStep,
  uiMessage,
}: {
  currentStep: number;
  getNextStep: () => void;
  previousStep: () => void;
  uiMessage?: {
    title?: string;
    text?: string;
  };
}) => {
  const [currentStepStart, setCurrentStepStart] = useState(currentStep);
  return (
    <>
      {currentStepStart === -1 && !uiMessage && (
        <Container>
          <img
            src={BlueHart}
            width="50"
            height="55"
            alt="curecall blue heart"
            className="mx-auto"
          />

          <h1 className="text-center">
            Chcielibyśmy zadać Ci kilka pytań, aby ocenić jakość Twojego życia w związku z chorobą oczu.
          </h1>

          <button
            className="mt-auto"
            onClick={() => {
              setCurrentStepStart(currentStepStart + 1);
              getNextStep();
            }}
          >
            NASTĘPNY
          </button>
        </Container>
      )}
    </>
  );
};
