// @ts-ignore
import { DynamicFormProps } from "../types/dynamic-form";
import { MedicalHistoryFormStart } from "./MedicalHistoryFormStart";
import { MedicalHistoryFormEnd } from "./MedicalHistoryFormEnd";
import { useEffect, useState } from "react";
import { MedicalHistorySingleQuestion } from "./MedicalHistorySingleQuestion";

export const MedicalHistoryDynamicForm = ({
  formVariables,
  currentStep,
  getNextStep,
  patient,
  resumeStep,
}: DynamicFormProps) => {
  const [formVariablesGrouped, setFormVariablesGrouped] = useState<any[]>([]);
  const [isGroupVariableQuestion, setIsGroupVariableQuestion] =
    useState<boolean>(true);
  const [currentGroupVariableQuestion, setCurrentGroupVariableQuestion] =
    useState<number>(-1);
  const [currentGroupVariable, setCurrentGroupVariable] = useState<number>(-1);
  const [currentVariable, setCurrentVariable] = useState<any>(undefined);

  const getNextGroupVariable = () => {
    setCurrentGroupVariableQuestion(-1);
    setCurrentGroupVariable(currentGroupVariable + 1);
    setIsGroupVariableQuestion(true);
  };

  const getNextGroupVariableQuestion = () => {
    if (resumeStep && resumeStep !== -1 && currentGroupVariable === -1) {
      const groupIndex = formVariablesGrouped.findIndex(
        (item) => item.group === formVariables[resumeStep].Variable.group
      );
      const questionIndex = formVariablesGrouped[groupIndex]?.items?.findIndex(
        (item: any) =>
          item.Variable.id === formVariables[resumeStep].Variable.id
      );
      setCurrentGroupVariableQuestion(questionIndex);
      setCurrentGroupVariable(groupIndex);
      setIsGroupVariableQuestion(questionIndex === -1);
    } else {
      if (
        currentGroupVariable !== -1 &&
        formVariablesGrouped[currentGroupVariable].items.length &&
        currentGroupVariableQuestion <
          formVariablesGrouped[currentGroupVariable].items.length - 1
      ) {
        setCurrentGroupVariableQuestion(currentGroupVariableQuestion + 1);
        setIsGroupVariableQuestion(false);
      } else {
        setCurrentGroupVariable(currentGroupVariable + 1);
        setCurrentGroupVariableQuestion(-1);
        setIsGroupVariableQuestion(true);
      }
    }
  };

  useEffect(() => {
    const tmp: any[] = [];
    if (formVariables.length) {
      // @ts-ignore
      for (const formVariable: any of formVariables) {
        const currentItem = tmp.find(
          (item) => item.group === formVariable.Variable.group
        );
        if (currentItem) {
          currentItem.items.push(formVariable);
        } else {
          tmp.push({
            formVariableGroup: formVariable,
            items: [],
            group: formVariable.Variable.group,
          });
        }
      }
    }
    setFormVariablesGrouped(tmp);
  }, [formVariables]);

  useEffect(() => {
    if (
      currentGroupVariable !== -1 &&
      currentGroupVariable < formVariablesGrouped.length &&
      currentGroupVariableQuestion === -1
    ) {
      setCurrentVariable(
        formVariablesGrouped[currentGroupVariable]?.formVariableGroup
      );
    }
  }, [
    currentGroupVariable,
    formVariablesGrouped,
    currentGroupVariableQuestion,
  ]);

  useEffect(() => {
    if (
      currentGroupVariableQuestion !== -1 &&
      currentGroupVariableQuestion <
        formVariablesGrouped[currentGroupVariable]?.items?.length
    ) {
      setCurrentVariable(
        formVariablesGrouped[currentGroupVariable]?.items[
          currentGroupVariableQuestion
        ]
      );
    }
  }, [
    currentGroupVariableQuestion,
    formVariablesGrouped,
    currentGroupVariable,
  ]);

  return (
    <>
      {currentGroupVariable === -1 && (
        <MedicalHistoryFormStart getNextStep={getNextGroupVariableQuestion} />
      )}
      {currentVariable &&
        currentGroupVariable !== formVariablesGrouped.length && (
          <MedicalHistorySingleQuestion
            formVariable={currentVariable}
            getNextStep={getNextGroupVariable}
            currentStep={formVariables?.findIndex(
              (item: any) => item.Variable.id === currentVariable?.Variable.id
            )}
            formVariablesLength={formVariables.length}
            isGroupVariableQuestion={isGroupVariableQuestion}
            getNextGroupVariableQuestion={getNextGroupVariableQuestion}
            GroupVariableItems={
              formVariablesGrouped[currentGroupVariable].items
            }
          />
        )}
      {currentGroupVariable === formVariablesGrouped.length && (
        <MedicalHistoryFormEnd />
      )}
    </>
  );
};
