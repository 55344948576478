import styled from "@emotion/styled";
import { Button } from "../";
import { colors } from "../../constants";

export const EyeTestContainer = styled("div")({
  width: "100%",
  background: "white",
  color: colors.BLUE,
});

export const BottomContainer = styled("div")({
  paddingRight: "2rem",
  paddingLeft: "2rem",
  position: "absolute",
  bottom: "3%",
});

export const LargeBottomButton = styled(Button)`
  & > span {
    line-height: 22px;
    font-family: "Luciole Regular";
  }
`;

export const BottomButton = styled(Button)`
  & > span {
    line-height: 22px;
    font-size: 18px;
    font-family: "Luciole Regular";
  }
`;

export const BottomContainerInstruction = styled("div")`
  width: 80vw;
  position: absolute;
  top: 85%;
  padding-right: 2rem;
  padding-left: 2rem;
`;

export const BottomButtonFullWidth = styled(LargeBottomButton)`
  width: 100vw;
`;

export const TitleInstruction = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  color: #555555;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Luciole Regular";
  line-height: 1.5rem;
  left: 18vw;
`;

export const ButtonInstruction = styled(Button)`
  width: 96%;
  bottom: 3%;
  position: fixed;
  font-family: "Luciole Bold";
`;

export const CenteredContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LargeButton = styled(Button)`
  font-family: "Luciole Bold";
`;
