import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("ErrorBoundary: componentDidCatch");
    console.log("Error :", error);
    console.log("Error message :", error.message);
    console.log("Error Info :", errorInfo);
    this.setState({ ...this.state, error: error.message, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <div className="mt-5">
            {JSON.stringify(this.state.error, null, 2)}
          </div>
          <div>{JSON.stringify(this.state.errorInfo, null, 2)}</div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
