import { Container } from "../../../components";
import CurecallBot from "../../../assets/images/svg/CurecallBot.svg";
import { CustomButton } from "./TutoHorusDynamicForm";

export const TutoHorusEnd = ({ resetForm }: { resetForm: () => void }) => (

  <Container className="text-center">
    <h1>Samouczek został ukończony</h1>
    <img
      src={CurecallBot}
      alt="logo-curecall"
      className="mx-auto"
      width="240"
    />

    <section className="gap-4 mt-auto">
      {<h2>Dzięki za ćwiczenie</h2>}
      <CustomButton onClick={resetForm}>SPRÓBUJ PONOWNIE</CustomButton>
    </section>
  </Container>
);
