import {
  FormOpacityStart,
  FormOpacityEnd,
  SetOfOpacityQuestions,
} from "../../../components";
import { DynamicFormProps } from "../types/dynamic-form";

export const OpacityDynamicForm = ({
  formVariables,
  currentStep,
  getNextStep,
}: DynamicFormProps) => {
  const formVariablesLenght = formVariables.length;
  return (
    <>
      {currentStep === -1 && <FormOpacityStart getNextStep={getNextStep} />}

      {currentStep !== -1 && currentStep !== formVariablesLenght && (
        <SetOfOpacityQuestions
          variable={formVariables[currentStep]}
          getNextStep={getNextStep}
        />
      )}

      {currentStep === formVariablesLenght && <FormOpacityEnd />}
    </>
  );
};
