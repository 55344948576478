import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PatientFormService } from "../../../services";

interface Props {
  fontScale: number;
  getNextStep: () => void;
  patient?: any;
  variableId: number;
}

const AcceptConsent: React.FC<Props> = ({
  getNextStep,
  fontScale,
  patient,
  variableId,
}) => {
  const { patientFormId } = useParams();

  const [location, setLocation] = useState<any>(undefined);
  const [contentUrl, setContentUrl] = useState<any>("");

  useEffect(() => {
    if (patient?.Project?.Locations?.length) {
      setLocation(patient?.Project?.Locations[0]);
    }
    setContentUrl(
      `https://content.curecall.com/fr/${patient?.Project?.name
        ?.replace(/ /g, "_")
        .toLowerCase()}`
    );
  }, [patient]);

  const handleResponse = async (value: string) => {
    const data = {
      VariableId: variableId,
      VariableOptionId: 0,
      patientAnswer: value,
    };

    await PatientFormService.postValueToPatientFormByPatientFormId(
      patientFormId || "",
      data
    );

    getNextStep();
  };

  let patientName = "";

  if (patient) {
    const gender = patient.gender === "MALE" ? "M." : "Mme";

    patientName = `${gender} ${patient.firstname} ${patient.lastname}`;
  }

  return (
    <>
      <h1
        style={{
          fontSize: `${1.9 + fontScale}rem`,
        }}
      >
        Bonjour <br /> {patientName},
      </h1>

      <div
        className="flex flex-col gap-8"
        style={{
          fontSize: `${1.4 + fontScale}rem`,
        }}
      >
        <p>
          {`Votre ophtalmologue ${location ? "du cabinet" : ""}`}{" "}
          {location && (
            <u>
              <b>{`${location.name}`}</b>
            </u>
          )}{" "}
          souhaite communiquer avec vous par SMS tout au long de votre suivi
          médical d’ophtalmologie pour vous communiquer des informations et
          échanger avec vous.
        </p>

        <p>
          Cette communication est opérée par l'entreprise française Curecall.
          Vos données sont partagées uniquement avec votre cabinet
          d'ophtalmologie et ce tel que défini dans{" "}
          <a
            style={{
              fontSize: `${1.4 + fontScale}rem`,
            }}
            href={`${contentUrl}/cgu/`}
            target="_blank"
            rel="noreferrer"
          >
            les conditions générales d'utilisation du service
          </a>{" "}
          et{" "}
          <a
            style={{
              fontSize: `${1.4 + fontScale}rem`,
            }}
            href={`${contentUrl}/privacypolicy/`}
            target="_blank"
            rel="noreferrer"
          >
            la politique de confidentialité du service
          </a>{" "}
          que vous acceptez sans réserve.
        </p>

        <p>
          Vous pouvez retirer votre consentement à tout moment en vous adressant
          à votre cabinet médical.
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <button
          className="bg-white text-primary"
          onClick={() => handleResponse("0")}
          style={{
            fontSize: `${1.7 + fontScale}rem`,
          }}
        >
          Je refuse
        </button>
        <button
          onClick={() => handleResponse("1")}
          style={{
            fontSize: `${1.7 + fontScale}rem`,
          }}
        >
          Je consens
        </button>
      </div>
    </>
  );
};

export default AcceptConsent;
