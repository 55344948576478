import { useState } from "react";
import { EyeOpacity, Container } from "../../../components";
import BlueHandEye from "../../../assets/images/svg/BlueHandEye.svg";
import BlueEyeHand from "../../../assets/images/svg/BlueEyeHand.svg";

interface StartPageProps {
  type: string;
  handLogo: string;
  alt: string;
  header_text: string;
  h3_text: string;
  span_text: string;
  percentage: number;
}

const getStartPageProps = (type: string) => {
  if (type === "contrastPerceptionR") {
    return {
      type: "amslerGridDistordedBlurredOrMissingAreasR",
      handLogo: BlueHandEye,
      alt: "right-eye-hide",
      header_text: "ŒIL DROIT",
      h3_text: "Cachez votre œil gauche",
      span_text: "Démarer mon test de l'œil droit ouvert",
      percentage: 0.5,
    };
  } else {
    return {
      type: "contrastPerceptionL",
      handLogo: BlueEyeHand,
      alt: "left-eye-hide",
      header_text: "ŒIL GAUCHE",
      h3_text: "Cachez votre œil droit",
      span_text: "Continuer mon test de l’œil gauche !",
      percentage: 0,
    };
  }
};

export const SetOfOpacityQuestions = ({
  variable,
  getNextStep,
}: {
  variable: any;
  getNextStep: () => void;
}) => {
  const name = variable.Variable.name;
  const startPageProps: StartPageProps = getStartPageProps(name);

  const [opacityQuestionsLocalStep, setOpacityQuestionsLocalStep] =
    useState(-1);

  const resetOpacityQuestionsLocalStep = () => {
    setOpacityQuestionsLocalStep(-1);
  };

  return (
    <>
      {opacityQuestionsLocalStep === -1 && (
        <Container>
          <progress max="100" value={startPageProps.percentage * 100} />

          <h1 className="text-center">{startPageProps.header_text}</h1>

          <img
            src={startPageProps.handLogo}
            style={{ width: "13rem" }}
            alt={startPageProps.alt}
            className="mx-auto"
          />

          <p className="text-center">{startPageProps.h3_text}</p>

          <button
            className="mt-auto"
            onClick={() => {
              setOpacityQuestionsLocalStep(opacityQuestionsLocalStep + 1);
            }}
          >
            {startPageProps.span_text}
          </button>
        </Container>
      )}

      {opacityQuestionsLocalStep === 0 && (
        <EyeOpacity
          startPageProps={startPageProps}
          variableId={variable.Variable.id}
          variableOptions={variable.Variable.VariableOptions}
          getNextStep={getNextStep}
          resetOpacityQuestionsLocalStep={resetOpacityQuestionsLocalStep}
        />
      )}
    </>
  );
};
