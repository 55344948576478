import { CircularProgress } from "@material-ui/core";
import { Container } from "../../../components";
import { PatientFormService } from "../../../services";
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";

export const MedicalHistorySingleQuestion = ({
  formVariable,
  currentStep,
  getNextStep,
  formVariablesLength,
  isGroupVariableQuestion,
  getNextGroupVariableQuestion,
  GroupVariableItems,
}: {
  formVariable: any;
  currentStep: number;
  formVariablesLength: number;
  getNextStep: () => void;
  isGroupVariableQuestion: boolean;
  getNextGroupVariableQuestion: () => void;
  GroupVariableItems: any[];
}) => {
  const { patientFormId } = useParams();

  const otherResponseRef = React.useRef<any>(undefined);

  const [floatValue, setFloatValue] = useState(0);

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setFloatValue(0);
  }, [formVariable]);

  const onchangeInputNumber = (event: {
    preventDefault: () => void;
    target: { value: any };
  }) => {
    setFloatValue(Number(event.target.value));
  };

  const handleResponse = async (value: string) => {
    setWaiting(true);
    if (isGroupVariableQuestion && value !== "1") {
      const data = GroupVariableItems.map((item) => ({
        VariableId: item.Variable.id,
        VariableOptionId: 0,
        patientAnswer: value,
      }));
      try {
        await PatientFormService.postBatchValueToPatientFormByPatientFormId(
          patientFormId || "",
          data
        );
        setWaiting(false);
      } catch (e) {}
    }
    await PatientFormService.postValueToPatientFormByPatientFormId(
      patientFormId || "",
      {
        VariableId: formVariable?.Variable.id,
        VariableOptionId: 0,
        patientAnswer: value,
      }
    );
    isGroupVariableQuestion && value !== "1"
      ? getNextStep()
      : getNextGroupVariableQuestion();
    setWaiting(false);
  };

  if (waiting) {
    return (
      <Container>
        <progress value={(currentStep / formVariablesLength) * 100} max="100" />

        <h1>{formVariable?.Variable.label}</h1>

        <CircularProgress className="mx-auto" />
      </Container>
    );
  }

  return (
    <Container>
      <progress value={(currentStep / formVariablesLength) * 100} max="100" />

      <h1>{formVariable?.Variable.label}</h1>

      {(formVariable.Variable.type === "INTEGER_VALUE_RANGE" ||
        formVariable.Variable.type === "INTEGER") && (
        <section className="flex flex-col gap-4 mt-auto">
          <button
            disabled={waiting}
            onClick={() => {
              handleResponse("1");
            }}
          >
            OUI
          </button>
          <button
            disabled={waiting}
            onClick={() => {
              handleResponse("0");
            }}
          >
            NON
          </button>
          {isGroupVariableQuestion && (
            <button
              className="text-lg"
              disabled={waiting}
              onClick={() => {
                handleResponse("2");
              }}
            >
              Je souhaite en parler au médecin
            </button>
          )}
        </section>
      )}

      {formVariable.Variable.type === "BOOLEAN" && (
        <section className="flex flex-col gap-4 mt-auto">
          <button
            disabled={waiting}
            onClick={() => {
              handleResponse("1");
            }}
          >
            OUI
          </button>
          <button
            disabled={waiting}
            onClick={() => {
              handleResponse("0");
            }}
          >
            NON
          </button>
        </section>
      )}

      {(formVariable.Variable.type === "STRING" ||
        formVariable.Variable.type === "FLOAT") && (
        <>
          {formVariable.Variable.type === "STRING" ? (
            <section className="flex flex-col gap-2">
              <label htmlFor="comment">Commentaire</label>
              <textarea
                placeholder="Réponse"
                ref={otherResponseRef}
                name="comment"
                id="comment"
                rows={10}
              />
            </section>
          ) : (
            <input
              ref={otherResponseRef}
              type="number"
              min={0}
              max={100}
              step={1}
              value={floatValue}
              onChange={onchangeInputNumber}
              className="w-[110px] mx-auto"
            />
          )}

          <button
            className="mt-auto"
            disabled={waiting}
            onClick={() => {
              handleResponse(otherResponseRef?.current?.value || " ");
            }}
          >
            CONTINUER
          </button>
        </>
      )}
    </Container>
  );
};
