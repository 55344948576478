import { useState, useEffect } from "react";

import ZoomMinus from "../../assets/images/svg/ZoomMinus.svg";
import ZoomPlus from "../../assets/images/svg/ZoomPlus.svg";
import ZoomCard from "../../assets/images/svg/ZoomCard.svg";

import { Container } from "../../components";

import { sessionStorageKey } from "../../constants";

interface MeasurementPageProps {
  getNextStep: () => void;
}

const MeasurementPage = ({ getNextStep }: MeasurementPageProps) => {
  const [imageWidth, setImageWidth] = useState(285);

  const onZoom = () => {
    setImageWidth(imageWidth + 5);
  };

  const onUnZoom = () => {
    setImageWidth(imageWidth - 5);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sessionStorage.setItem(sessionStorageKey.imageWidth, String(imageWidth));
  }, [imageWidth]);

  return (
    <Container>
      <h1 className="text-center">
        Collez votre carte sur
        <br />
        le dessin
      </h1>

      <section className="text-center">
        Ajustez la taille avec les bouton
        <span className="flex items-center justify-center">
          <img
            src={ZoomPlus}
            width="22"
            height="18"
            alt="zoom-plus-icon"
            style={{ margin: "0 7px" }}
          />
          et
          <img
            className="rotate-[180deg]"
            src={ZoomMinus}
            width="22"
            height="18"
            alt="zoom-minus-icon"
            style={{ margin: "0 7px" }}
          />
        </span>
      </section>

      <section className="flex flex-col items-center gap-4">
        <img
          onClick={onUnZoom}
          src={ZoomMinus}
          alt="circle-minus"
          width="41"
          height="41"
        />

        <img src={ZoomCard} alt="zoom-card" width={imageWidth} />

        <img
          className="rotate-[180deg]"
          onClick={onZoom}
          src={ZoomPlus}
          alt="circle-add"
          width="41"
          height="41"
        />
      </section>

      <button
        className="mt-auto"
        onClick={() => {
          getNextStep();
        }}
      >
        JE VALIDE
      </button>
    </Container>
  );
};

export default MeasurementPage;
