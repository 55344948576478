import { useState } from "react";
import { Container } from "../../../components";

import { Amsler } from "./Amsler";

import BlueHandEye from "../../../assets/images/svg/BlueHandEye.svg";
import BlueEyeHand from "../../../assets/images/svg/BlueEyeHand.svg";

interface StartPageProps {
  type: string;
  handLogo: string;
  alt: string;
  header_text: string;
  h3_text: string;
  span_text: string;
  eye: string;
  eye_to_mask: string;
  percentage: number;
}

export const SetOfAmslerQuestions = ({
  variable,
  getNextStep,
  loadPatientFormId,
}: {
  variable: any;
  getNextStep: () => void;
  loadPatientFormId: (value: number) => void;
}) => {
  const getStartPageProps = (type: string) => {
    if (type === "amslerGridDistordedBlurredOrMissingAreasL") {
      return {
        type: "amslerGridDistordedBlurredOrMissingAreasL",
        handLogo: BlueEyeHand,
        alt: "left-eye-hide",
        header_text: "ŒIL GAUCHE",
        eye_to_mask: "DROIT",
        eye: "GAUCHE",
        h3_text: "Cachez votre œil droit",
        span_text: "Démarrer mon test de l’œil gauche !",
        percentage: 0,
      };
    } else {
      return {
        type: "amslerGridDistordedBlurredOrMissingAreasR",
        handLogo: BlueHandEye,
        alt: "right-eye-hide",
        header_text: "ŒIL DROIT",
        eye_to_mask: "GAUCHE",
        eye: "DROIT",
        h3_text: "Cachez votre œil gauche",
        span_text: " Démarrer mon test de l’œil droit !",
        percentage: 0.5,
      };
    }
  };

  // const { patientFormId } = useParams();
  const name = variable.Variable.name;
  const startPageProps: StartPageProps = getStartPageProps(name);

  const [amslerQuestionsLocalStep, setAmslerQuestionsLocalStep] = useState(-1);

  const resetAmslerLocalStep = () => {
    setAmslerQuestionsLocalStep(-1);
  };

  return (
    <>
      {amslerQuestionsLocalStep === -1 && (
        <Container>
          <progress max="100" value={startPageProps.percentage * 100} />

          <h1 className="text-center">
            CACHEZ VOTRE ŒIL
            <br />
            {startPageProps.eye_to_mask}
          </h1>

          <img
            src={startPageProps.handLogo}
            alt={startPageProps.alt}
            style={{ width: "13rem" }}
            className="mx-auto"
          />

          <p className="text-center">{startPageProps.h3_text}</p>

          <button
            className="mt-auto"
            onClick={() => {
              setAmslerQuestionsLocalStep(amslerQuestionsLocalStep + 1);
            }}
          >
            {startPageProps.span_text}
          </button>
        </Container>
      )}
      {amslerQuestionsLocalStep === 0 && (
        <Amsler
          startPageProps={startPageProps}
          variableId={variable.Variable.id}
          variableOptions={variable.Variable.VariableOptions}
          getNextStep={getNextStep}
          resetAmslerLocalStep={resetAmslerLocalStep}
          loadPatientFormId={loadPatientFormId}
        />
      )}
    </>
  );
};
