import "./App.css";
// Import Pages
import Login from "./pages/Auth/Login";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import DynamicForm from "./pages/DynamicForm";
import FormErrorPage from "./pages/FormErrorPage";
import ViewOnMobilePage from "./pages/ViewOnMobilePage";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import useAuth, { AuthProvider } from "./hooks/useAuth/useAuth";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import AutoLogin from "./pages/Auth/AutoLogin";
import { TutoHorusDynamicForm } from "./pages/forms/tutoHorus/TutoHorusDynamicForm";
// // Auth Page Middleware Route

const AuthenticatedRoute = ({ children }: any) => {
  const { token } = useAuth();
  return token ? children : <Navigate to="/" />;
};

// Optional parameters in routes is not supported as of now

const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <>
            <div className="App">
              <Routes>
                <Route
                  path="/login/:patientId/:patientFormId"
                  element={<Login />}
                />
                <Route
                  path="/auto-login/:patientId/:patientFormId/:hash"
                  element={<AutoLogin />}
                />
                <Route path="/login/:patientId" element={<Login />} />
                <Route
                  path="/form/:patientFormId"
                  element={
                    <AuthenticatedRoute>
                      <DynamicForm />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/form-tutorial"
                  element={<TutoHorusDynamicForm />}
                />
                <Route path="/formNotAvailable" element={<FormErrorPage />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>

            <div className="Desktop">
              <ViewOnMobilePage />
            </div>
          </>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
