import { VisualAccuityTest } from "../../../components";
import { useState } from "react";
import { useParams } from "react-router";
import { PatientFormService } from "../../../services";
import { sessionStorageKey } from "../../../constants";
import CenteredLoader from "../../../components/atoms/centeredLoader/centeredLoader";

interface AcuityDataItem {
  VariableId: number;
  VariableOptionId: number;
  patientAnswer: string;
}

const getSessionStorageKey = (type: string) => {
  if (type === "visualAcuityR") {
    return sessionStorageKey.visualAcuityRight;
  }
  if (type === "visualAcuityL") {
    return sessionStorageKey.visualAcuityLeft;
  } else {
    return sessionStorageKey.visualAcuityBoth;
  }
};

export const EyeAcuity = ({
  variableId,
  variableOptions,
  getNextStep,
  startPageProps,
  resetAcuityQuestionsLocalStep,
}: any) => {
  const [acuityResponsesData, setAcuityResponsesData] = useState<
    AcuityDataItem[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  const [localStep, setLocalStep] = useState(0);
  const { patientFormId } = useParams();
  const item = variableOptions[localStep];
  const sessionStorageKey = getSessionStorageKey(startPageProps.type);

  const onSelectResponse = async (answer: string) => {
    const isValid = answer === item.target;
    const responsesItem = sessionStorage.getItem(sessionStorageKey);
    let responses = responsesItem === null ? [] : JSON.parse(responsesItem);
    const result = {
      size: item.UISize,
      value: item.value,
      isValid,
    };
    responses = [...responses, result];
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(responses));

    const data: AcuityDataItem = {
      VariableId: variableId,
      VariableOptionId: item.id,
      patientAnswer: answer,
    };

    await setAcuityResponsesData([...acuityResponsesData, data]);

    await setLocalStep((prevLocalStep) => prevLocalStep + 1);
    if (localStep === variableOptions.length - 1) {
      setIsLoading(true);
      const patientResponses = [...acuityResponsesData, data].filter((item) => {
        const option = (variableOptions as any[]).find(
          (itemOption) => itemOption.id === item.VariableOptionId
        );
        return option.target === item.patientAnswer;
      });

      const lastPatientResponse = patientResponses.length
        ? patientResponses[patientResponses.length - 1]
        : acuityResponsesData[0];

      await PatientFormService.postValueToPatientFormByPatientFormId(
        patientFormId || "",
        { ...lastPatientResponse, lastVariableOption: true }
      );

      setIsLoading(false);
      resetAcuityQuestionsLocalStep();
      setAcuityResponsesData([]);
      getNextStep();
    }
  };

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <>
      <h1 className="text-center">{startPageProps.header_text}</h1>

      {item && (
        <VisualAccuityTest
          item={item}
          onSelectResponse={onSelectResponse}
          getNextLocalStep={setLocalStep}
        />
      )}
    </>
  );
};
