import { ReactNode } from "react";

interface FlexCenterProps {
  children: ReactNode;
}

export const FlexCenter = ({ children }: FlexCenterProps): JSX.Element => {
  const flexCenterStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "80%",

    "&.absolute": {
      position: "absolute",
      left: 0,
      top: 0,
    },
    "&.scanned": {
      position: "relative",
      width: 224,
      height: 222,
      minHeight: 222,
    },
  };

  return <div style={flexCenterStyle}> {children}</div>;
};
