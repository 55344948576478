interface Props {
  fontScale: number;
}

const ConsentFormEnd: React.FC<Props> = ({ fontScale }) => {
  return (
    <h1
      className="text-center py-8"
      style={{
        fontSize: 1.4 + fontScale + "rem",
      }}
    >
      Merci, votre réponse a été communiquée à votre cabinet d’ophtalmologie.
    </h1>
  );
};

export default ConsentFormEnd;
