import { useEffect, useState } from "react";
import { Container } from "../../../components";

interface Props {
  children: React.ReactNode;
  projectName: string;
  increaseFontScale: () => void;
  decreaseFontScale: () => void;
}

const doesImageExist = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => reject(false);
  });

const ConsentWrapper: React.FC<Props> = ({
  children,
  projectName,
  increaseFontScale,
  decreaseFontScale,
}) => {
  const [logo, setLogo] = useState<any>();

  useEffect(() => {
    const projectLogoUrl = `https://media.curecall.com/software/client/${projectName
      .replace(/ /g, "_")
      .toLowerCase()}/logo.png`;

    doesImageExist(projectLogoUrl)
      .then(() => {
        setLogo(projectLogoUrl);
      })
      .catch(() => {
        setLogo(`https://media.curecall.com/software/client/default/logo.png`);
      });
  }, [projectName]);

  return (
    <Container>
      <div className="flex justify-between mb-4">
        {logo && (
          <img src={logo} alt={`${projectName} Logo`} className="max-h-20" />
        )}

        <div className="flex gap-2 items-end">
          <button
            className="text-white font-medium text-xl p-0 w-12 h-12 bg-[#33CCCC]"
            onClick={decreaseFontScale}
          >
            -A
          </button>
          <button
            className="text-white font-medium text-2xl p-0 w-16 h-16 bg-[#0800FF]"
            onClick={increaseFontScale}
          >
            +A
          </button>
        </div>
      </div>

      {children}
    </Container>
  );
};

export default ConsentWrapper;
