import { useState, ComponentPropsWithRef } from "react";

import { TutoHorusStart } from "./TutoHorusStart";
import { TutoHorusSingleQuestion } from "./TutoHorusSingleQuestion";
import { TutoHorusEnd } from "./TutoHorusEnd";
import { twMerge } from "tailwind-merge";

type Props = ComponentPropsWithRef<"button">;

export const CustomButton = ({
  onClick,
  disabled,
  children,
  className,
}: Props) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={twMerge(
        "w-full bg-primary text-white flex items-center justify-center py-3 leading-normal text-2xl mt-8 rounded-full font-bold",
        disabled && "bg-grey-400 border-grey-400 cursor-not-allowed",
        className
      )}
    >
      {children}
    </button>
  );
};

export const horusParagraphTextStyle =
  "text-black text-center mt-24 ml-6 mr-6 mb-6 text-lg";

export const LucioleBoldStyle = { fontFamily: "Luciole Bold" };

export const TutoHorusDynamicForm = () => {
  const [currentFormStep, setCurrentFormStep] = useState(-1);
  const tutoVariables = [
    {
      label: "Oto pytanie ze skalą od 0 do 100",
      id: 1,
      type: "INTEGER_VALUE_RANGE",
    },
    {
      label: "Samouczek: Krok 2",
      label2: "Oto pytanie z tylko jedną możliwą odpowiedzią",
      id: 1,
      type: "OPTIONS",
      VariableOptions: [
        {
          answerOptions: "Odpowiedź A",
        },
        {
          answerOptions: "Odpowiedź B",
        },
        {
          answerOptions: "Odpowiedź C",
        },
        {
          answerOptions: "Odpowiedź D",
        },
      ],
    },
  ];

  const nextStep = () => {
    setCurrentFormStep(currentFormStep + 1);
  };

  const nextVariableStep = async () => {
    nextStep();
  };

  const previousStep = async () => {
    setCurrentFormStep(currentFormStep - 1);
  };

  const resetForm = async () => {
    setCurrentFormStep(-1);
  };

  return (
    <>
      {tutoVariables.length > 0 && (
        <>
          {currentFormStep === -1 && (
            <TutoHorusStart
              currentStep={currentFormStep}
              getNextStep={nextStep}
            />
          )}
          {currentFormStep !== -1 &&
            currentFormStep !== tutoVariables.length && (
              <TutoHorusSingleQuestion
                formVariable={tutoVariables[currentFormStep]}
                getNextStep={nextVariableStep}
                previousStep={previousStep}
                currentStep={currentFormStep}
                formVariablesLength={tutoVariables.length}
              />
            )}
          {currentFormStep === tutoVariables.length && (
            <TutoHorusEnd resetForm={resetForm} />
          )}
        </>
      )}
    </>
  );
};
