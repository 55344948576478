import { Container } from "../components";
import logo from "../assets/images/svg/LogoCurecall.svg";

const FormErrorPage = () => {
  const locale = navigator.language;

  if (locale.includes("pl")) {
    return (
      <Container className="text-center">
        <h1 className="p-4 bg-cyan-300 rounded-md">Witam</h1>

        <img
          src={logo}
          width="89"
          height="81"
          alt="logo-curecall"
          className="mx-auto"
        />

        <h2>Niniejszy kwestionariusz został wypełniony lub wygasł.</h2>
      </Container>
    );
  }

  return (
    <Container className="text-center">
      <h1 className="p-4 bg-cyan-300 rounded-md">Bonjour</h1>

      <img
        src={logo}
        width="89"
        height="81"
        alt="logo-curecall"
        className="mx-auto"
      />

      <h2>Ce questionnaire est terminé ou expiré.</h2>
    </Container>
  );
};

export default FormErrorPage;
