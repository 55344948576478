import { forwardRef } from "react";
import "./slider.css";
import { LucioleBoldStyle } from "../tutoHorus/TutoHorusDynamicForm";

export type CurecallSliderProps = {
  id: string;
  min?: number;
  max?: number;
  value: string;
  setValue: (newValue: string) => void;
  caption1: string;
  caption2: string;
};

export type CurecallSliderRef = HTMLInputElement;

export const CurecallSlider = forwardRef<
  CurecallSliderRef,
  CurecallSliderProps
>(({ id, min = 0, max = 100, value, setValue, caption1, caption2 }, ref) => {
  return (
    <div className="relative w-full">
      <span
        className="text-lg text-black font-bold absolute start-1 -top-2"
        style={LucioleBoldStyle}
      >
        0
      </span>
      <span
        className="text-lg text-black font-bold absolute end-0 -top-2"
        style={LucioleBoldStyle}
      >
        100
      </span>
      <input
        type="range"
        id={id}
        min={min}
        max={max}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        className="custom-slider"
        step="1"
      />
      <span className="text-sm text-black absolute start-0 mt-14 w-36">
        {caption1}
      </span>
      <span className="text-sm text-black absolute end-0 mt-14 w-36 text-end">
        {caption2}
      </span>
    </div>
  );
});

export default CurecallSlider;
