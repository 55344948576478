import { sessionStorageKey } from "../../../constants";
import { twMerge } from "tailwind-merge";

export const VisualAccuityTest = ({
  item,
  onSelectResponse,
}: {
  item: any;
  onSelectResponse: (param: any) => any;
  getNextLocalStep: (param: number) => void;
}) => {
  return (
    <>
      <h2
        className={twMerge(
          `text-[${
            item.UISize *
            (Number(sessionStorage.getItem(sessionStorageKey.imageWidth)) / 153)
          }px] text-center`
        )}
      >
        {item.target}
      </h2>

      <p className="text-center">Sélectionnez ce que vous voyez :</p>

      <section className="flex flex-col gap-4">
        {item.answerOptions.map((answer: string, index: number) => {
          const isDontKnow = answer === "Je ne sais pas";
          return isDontKnow ? (
            <button
              key={answer + index}
              className="mt-auto"
              onClick={() => {
                onSelectResponse(answer);
              }}
            >
              {answer}
            </button>
          ) : (
            <button
              key={answer + index}
              className="mt-auto"
              onClick={() => {
                onSelectResponse(answer);
              }}
            >
              {answer}
            </button>
          );
        })}
      </section>
    </>
  );
};
