import logo from "../assets/images/svg/LogoCurecall.svg";
import { Container, FlexCenter } from "../components";

const Home = () => {
  return (
    <Container>
      <FlexCenter>
        <img src={logo} width="124" height="113" alt="logo-curecall" />
      </FlexCenter>
    </Container>
  );
};

export default Home;
