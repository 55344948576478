import { useState } from "react";

import BlueHart from "../../../assets/images/svg/BlueHart.svg";

import { Container } from "../../../components";

export const MedicalHistoryFormStart = ({
  getNextStep,
}: {
  getNextStep: () => void;
}) => {
  const [currentStepStart, setCurrentStepStart] = useState(-1);
  return (
    <>
      {currentStepStart === -1 && (
        <Container>
          <img
            src={BlueHart}
            width="50"
            height="55"
            alt="blue-heart"
            className="mx-auto"
          />

          <h1 className="text-center">
            Votre médecin souhaite vous poser quelques questions concernant vos
            antécédents médicaux.
          </h1>

          <button
            className="mt-auto"
            onClick={() => {
              setCurrentStepStart(currentStepStart + 1);
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {currentStepStart === 0 && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <p>
            Vos réponses sont précieuses et permettront à votre médecin de
            personnaliser votre prise en charge.
          </p>

          <p>
            Merci de répondre aux questions en choisissant la réponse qui
            correspond le mieux à votre situation et à votre état de santé.
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextStep();
            }}
          >
            COMMENCER
          </button>
        </Container>
      )}
    </>
  );
};
