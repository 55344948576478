import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import "./assets/css/typography.css";
import "./assets/css/linear-progress.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { init as initSentry } from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { SENTRY_ENVIRONMENTS } from "./constants";

if (SENTRY_ENVIRONMENTS.includes(process.env.REACT_APP_ENVIRONMENT as string)) {
  initSentry({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    release: `${process.env.REACT_APP_VERSION}`,
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
