import {
  AccuityFormStart,
  AccuityFormEnd,
  SetOfAcuityQuestions,
} from "../../../components";
import { DynamicFormProps } from "../types/dynamic-form";

export const AccuityDynamicForm = ({
  formVariables,
  currentStep,
  getNextStep,
}: DynamicFormProps) => {
  const formVariablesLength = formVariables.length;

  return (
    <>
      {currentStep === -1 && <AccuityFormStart getNextStep={getNextStep} />}
      {currentStep !== -1 && currentStep !== formVariablesLength && (
        <SetOfAcuityQuestions
          variable={formVariables[currentStep]}
          getNextStep={getNextStep}
        />
      )}
      {currentStep === formVariablesLength && <AccuityFormEnd />}
    </>
  );
};
