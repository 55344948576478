import { Container } from "../../../components";
import logo from "../../../assets/images/svg/LogoCurecall.svg";

export const FormQualityOfLifeHorusEnd = () => (
  <Container>

    <h1 className=" p-4 bg-cyan-300 text-center rounded-md">
      Wypełniony kwestionariusz
    </h1>

    <img
      src={logo}
      width="89"
      height="81"
      alt="logo-curecall"
      className="mx-auto"
    />

    <h2 className="text-center">
      Dziękujemy za wypełnienie kwestionariusza dotyczącego jakości życia w związku z chorobą oczu. Życzę miłego dnia.
    </h2>
  </Container>
);
