import styled from "@emotion/styled";
import { Button as AntdButton } from "antd";
import { colors } from "../../../constants";

export const WhiteButton = styled(AntdButton)({
  width: "95%",
  backgroundColor: "#fff",
  outline: "none",
  border: `2px solid ${colors.BLUE}`,
  boxShadow: "none",
  padding: "0.8rem 0rem",

  span: {
    fontFamily: "Luciole Bold",
    fontWeight: 900,
    fontSize: 25,
    lineHeight: "25px",
    textAlign: "center",
    color: colors.BLUE,
    whiteSpace: "normal",
    maxWidth: "100%",

    "@media (max-width: 430px)": {
      fontSize: 22,
    },

    "@media (max-width: 390px)": {
      fontSize: 18,
    },

    "@media (max-width: 320px)": {
      fontSize: 16,
    },
  },

  "&:disabled": {
    backgroundColor: colors.WHITE,
    border: `2px solid ${colors.BLUE}`,
    color: colors.BLUE,

    span: {
      color: colors.BLUE,
    },
  },

  "&.ant-btn-round": {
    borderRadius: 33,
  },

  "&:active, &focus": {
    backgroundColor: colors.BLUE,
    border: `none`,
    span: {
      color: colors.WHITE,
    },
  },
});
