import { Container } from "../../../components";
import { PatientFormService } from "../../../services";
import { useParams } from "react-router";
import { twMerge } from "tailwind-merge";
import { useRef, useState } from "react";

export const QualityOfLifeNEISingleQuestion = ({
  formVariable,
  currentStep,
  getNextStep,
  previousStep,
  formVariablesLength,
}: {
  formVariable: any;
  currentStep: number;
  formVariablesLength: number;
  getNextStep: (response: any) => void;
  previousStep: () => void;
}) => {
  const { patientFormId } = useParams();
  const ref = useRef<HTMLButtonElement>(null);
  const topRef = useRef<HTMLProgressElement>(null);
  const [selectedResponse, setSelectedResponse] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const handleResponse = async (value: string, VariableOptionId?: number) => {
    const body = {
      VariableId: formVariable?.Variable.id,
      VariableOptionId,
      patientAnswer: value,
      lastVariableOption: true,
    };

    setIsLoading(true);

    const { data }: any =
      await PatientFormService.postValueToPatientFormByPatientFormId(
        patientFormId || "",
        body
      );

    setIsLoading(false);
    topRef.current?.scrollIntoView();
    setSelectedResponse(undefined);
    getNextStep(await data);
  };

  const handleContinue = () => {
    if (selectedResponse === undefined || isLoading) return;

    const variableOption =
      formVariable.Variable.VariableOptions[selectedResponse];
    handleResponse(variableOption.answerOptions[0], variableOption.id);
  };

  return (
    <Container>
      <progress
        ref={topRef}
        max="100"
        value={(currentStep / formVariablesLength) * 100}
      />

      <u className="text-primary" onClick={() => previousStep()}>
        Page précédente
      </u>

      <h1 dangerouslySetInnerHTML={{ __html: formVariable?.Variable.label }} />

      {formVariable.Variable.type === "OPTIONS" && (
        <div className="flex flex-col gap-4">
          {formVariable.Variable.VariableOptions.map(
            (variableOption: any, index: number) => {
              const id = index + "-" + variableOption.answerOptions;
              return (
                <div
                  key={index}
                  className={twMerge(
                    "bg-primary-100 rounded-xl py-4 px-3 cursor-pointer flex items-center",
                    selectedResponse === index && "bg-primary"
                  )}
                  onClick={() => {
                    setSelectedResponse(index);
                  }}
                >
                  <input
                    id={id}
                    type="radio"
                    name="try-an-answer"
                    value={variableOption.answerOptions}
                    checked={selectedResponse === index}
                  />
                  <label
                    htmlFor={id}
                    className={twMerge(
                      selectedResponse === index
                        ? "text-white"
                        : "text-grey-900"
                    )}
                  >
                    {variableOption.answerOptions}
                  </label>
                </div>
              );
            }
          )}
        </div>
      )}
      {formVariable.Variable.type !== "BOOLEAN" &&
      <button
          ref={ref}
          className="mt-auto"
          onClick={handleContinue}
          disabled={selectedResponse === undefined}
      >
        CONTINUER
      </button>
      }
      {formVariable.Variable.type === "BOOLEAN" && (
          <div className="flex flex-col gap-4 ">
            <button
                disabled={isLoading}
                onClick={(e) => {
              handleResponse("1");
              e.currentTarget.blur();
            }}
          >
            OUI
          </button>

          <button
            disabled={isLoading}
            onClick={(e) => {
              handleResponse("0");
              e.currentTarget.blur();
            }}
          >
            NON
          </button>
        </div>
      )}
    </Container>
  );
};
