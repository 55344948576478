import { Container } from "../../../components";

import { useRef, useState } from "react";
import CurecallSlider from "./../qualityOfLifeHorus/CurecallSlider";
import { twMerge } from "tailwind-merge";
import HandSwitch from "../../../assets/images/svg/HandSwitch.svg";

export const TutoHorusSingleQuestion = ({
  formVariable,
  currentStep,
  getNextStep,
  previousStep,
  formVariablesLength,
}: {
  formVariable: any;
  currentStep: number;
  formVariablesLength: number;
  getNextStep: () => void;
  previousStep: () => void;
}) => {
  const topRef = useRef<HTMLProgressElement>(null);
  const [value, setValue] = useState<string>("50");
  const [selectedResponse, setSelectedResponse] = useState<number>();
  const handleNext = async () => {
    topRef.current?.scrollIntoView();
    getNextStep();
    setValue("0");
  };

  return (
    <Container>
      <progress
        ref={topRef}
        max="100"
        value={(currentStep / formVariablesLength) * 100}
      />

      <u className="text-primary" onClick={() => previousStep()}>
        Poprzednia strona
      </u>

      <h1>{formVariable?.label}</h1>

      {formVariable?.label2 && <h2>{formVariable?.label2}</h2>}

      {formVariable.type === "INTEGER_VALUE_RANGE" && (
        <>
          <section className=" flex flex-col gap-8 justify-center items-center">
            <CurecallSlider
              id={formVariable?.id.toString()}
              value={value}
              setValue={setValue}
              caption1={"Brak dyskomfortu"}
              caption2={"Nie do zniesienia"}
            />
            <img src={HandSwitch} alt="blue-heart" style={{ width: "10rem" }} />
            <p className="text-center">
               Przesuń środkowy kursor od lewej do prawej za pomocą palca
            </p>
          </section>
          <button className="mt-auto" onClick={handleNext}>
            GOTOWE
          </button>
        </>
      )}

      {formVariable.type === "OPTIONS" && (
        <form className="flex flex-grow flex-col items-center gap-8">
          <fieldset className="w-full flex flex-col gap-4">
            {formVariable.VariableOptions.map(
              (variableOption: any, index: number) => {
                const id = index + "-" + variableOption.answerOptions;
                return (
                  <div
                    key={index}
                    className={twMerge(
                      "bg-primary-100 rounded-xl py-4 px-3 cursor-pointer flex items-center",
                      selectedResponse === variableOption.answerOptions &&
                        "bg-primary"
                    )}
                    onClick={() => {
                      setSelectedResponse(variableOption.answerOptions);
                    }}
                  >
                    <input
                      id={id}
                      type="radio"
                      name="try-an-answer"
                      value={variableOption.answerOptions}
                      checked={
                        selectedResponse === variableOption.answerOptions
                      }
                    />
                    <label
                      htmlFor={id}
                      className={twMerge(
                        selectedResponse === variableOption.answerOptions
                          ? "text-white"
                          : "text-black"
                      )}
                    >
                      {variableOption.answerOptions}
                    </label>
                  </div>
                );
              }
            )}
          </fieldset>

          <p className="text-center">
          Wszystko, co musisz zrobić, to dotknąć wybranej odpowiedzi.
          </p>

          <button
            className="mt-auto"
            onClick={(e) => {
              e.preventDefault();
              handleNext();
            }}
            disabled={!selectedResponse}
          >
            GOTOWE
          </button>
        </form>
      )}
    </Container>
  );
};
