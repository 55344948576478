import {http} from "../../utils";
import PatientFormServiceType from "./PatientFormService.type";

export const PatientFormService: PatientFormServiceType = {
  getPatientFormsForCurrentUser: async (patientFormId) => {
    const { data } = await http.get(`/patients/patient-form`);
    return data.filter(
        (element: {
          id: number;
          sender: string;
          status: string;
          FormId: number;
          PatientId: number;
        }) => {
          if (
              patientFormId &&
              Number(patientFormId) === element.id &&
              element.status !== "COMPLETED" &&
              element.status !== "EXPIRED"
          ) {
            return element;
          }
          return null;
        }
    );
  },

  getPatientFormByFormId: async (formId) => {
    return await http.get(`/patients/form/${formId}`);
  },

  getFormByPatientFormId: async (patientFormId) => {
    return await http.get(`/patients/patient-form/${patientFormId}`);
  },

  postValueToPatientFormByPatientFormId: (patientFormId, data) => {
    return http.post(`patients/patient-form/${patientFormId}`, data);
  },

  postBatchValueToPatientFormByPatientFormId: (patientFormId, data) => {
    return http.post(`patients/patient-form/${patientFormId}/batch`, data);
  },

  postAmslerGrid: async (patientFormId, variableId, data) => {
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
    };
    return  http.post(
      `patients/patient-form/${patientFormId}/amsler-grid/${variableId}`,
      data,
      config
    );
  },


  deletePatientVariable: (patientFormId:number,patientVariableId:number) => {
    return http.delete(`patients/patient-form/${patientFormId}/variables/${patientVariableId}`);
  },
};
