import { useState } from "react";
import { useParams } from "react-router";
import { Container, VisualOpacityTest } from "../../../components";
import CenteredLoader from "../../../components/atoms/centeredLoader/centeredLoader";
import { PatientFormService } from "../../../services";

interface OpacityDataItem {
  VariableId: number;
  VariableOptionId: number;
  patientAnswer: string;
}

export const EyeOpacity = ({
  variableId,
  variableOptions,
  getNextStep,
  startPageProps,
  resetOpacityQuestionsLocalStep,
}: any) => {
  const [opacityResponsesData, setOpacityResponsesData] = useState<
    OpacityDataItem[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  const [localStep, setLocalStep] = useState(0);
  const { patientFormId } = useParams();
  const item = variableOptions[localStep];

  const onSelectResponse = async (answer: string) => {
    const data: OpacityDataItem = {
      VariableId: variableId,
      VariableOptionId: item.id,
      patientAnswer: answer,
    };

    setOpacityResponsesData([...opacityResponsesData, data]);

    setLocalStep(localStep + 1);
    if (localStep === variableOptions.length - 1) {
      setIsLoading(true);
      const patientResponses = [...opacityResponsesData, data].filter(
        (item) => {
          const option = (variableOptions as any[]).find(
            (itemOption) => itemOption.id === item.VariableOptionId
          );
          return option.target === item.patientAnswer;
        }
      );

      const lastPatientResponse = patientResponses.length
        ? patientResponses[patientResponses.length - 1]
        : opacityResponsesData[0];

      await PatientFormService.postValueToPatientFormByPatientFormId(
        patientFormId || "",
        { ...lastPatientResponse, lastVariableOption: true }
      );

      setIsLoading(false);
      setOpacityResponsesData([]);
      resetOpacityQuestionsLocalStep();
      getNextStep();
    }
  };

  if (isLoading) {
    return <CenteredLoader />;
  }
  return (
    <Container>
      <h1 className="text-center">{startPageProps.header_text}</h1>
      {item && (
        <VisualOpacityTest item={item} onSelectResponse={onSelectResponse} />
      )}
    </Container>
  );
};
