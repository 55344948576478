import { Container } from "../../../components";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import PhoneSolar from "../../../assets/images/svg/PhoneSolar.svg";
import BlueGlasses from "../../../assets/images/svg/BlueGlasses.svg";
import BlueMobileEye from "../../../assets/images/svg/BlueMobileEye.svg";
import BlueHart from "../../../assets/images/svg/BlueHart.svg";

interface CarouselItemType {
  logo: string;
  html: string;
  logoWidth: number;
}

const CAROUSEL_ITEMS = [
  {
    logo: PhoneSolar,
    logoWidth: 90,
    html: `Augmentez la luminosité de votre écran de téléphone au maximum.<br/>
    Mettez vous dans un lieu ou votre écran est correctement visible pour vous.`,
  },
  {
    logo: BlueGlasses,
    logoWidth: 172,
    html: `Si vous avez des lentilles de contact ou des lunettes,vous devez  <strong><u> les porter pendant le test</u></strong>`,
  },
  {
    logo: BlueMobileEye,
    logoWidth: 212,
    html: `Tenez votre téléphone à une distance de lecture confortable et constante durant tout le test`,
  },
];

const CarouselItem = ({ logo, html, logoWidth }: CarouselItemType) => (
  <>
    <img
      src={logo}
      style={{ height: "15vh" }}
      width={logoWidth}
      alt="carouselItemImg"
      className="mx-auto"
    />
    <p className="text-center" dangerouslySetInnerHTML={{ __html: html }} />
  </>
);

export const FormOpacityStart = ({
  getNextStep,
}: {
  getNextStep: () => void;
}) => {
  const [startCurrentStep, setStartCurrentStep] = useState(-1);
  const [carouselIndex, setCarouselIndex] = useState(0);

  return (
    <>
      {startCurrentStep === -1 && (
        <Container>
          <img
            src={BlueHart}
            alt="blue-heart"
            style={{ width: "3rem" }}
            className="mx-auto"
          />

          <h1 className="text-center">
            Votre médecin souhaite que vous testiez votre vision
          </h1>

          <button
            className="mt-auto"
            onClick={() => {
              setStartCurrentStep(startCurrentStep + 1);
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 0 && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <CarouselItem
            html={CAROUSEL_ITEMS[carouselIndex].html}
            logo={CAROUSEL_ITEMS[carouselIndex].logo}
            logoWidth={CAROUSEL_ITEMS[carouselIndex].logoWidth}
            key={`carousel-item-${carouselIndex}`}
          />

          <div className="flex gap-2 justify-center mt-auto">
            <span
              className={twMerge(
                `w-[42px] h-[12px] border rounded-sm mr-4 border-primary`,
                carouselIndex === 0 ? `bg-primary` : `bg-white`
              )}
              aria-hidden="true"
            />
            <span
              className={twMerge(
                `w-[42px] h-[12px] border rounded-sm mr-4 border-primary`,
                carouselIndex === 1 ? `bg-primary` : `bg-white`
              )}
              aria-hidden="true"
            />
            <span
              className={twMerge(
                `w-[42px] h-[12px] border rounded-sm mr-4 border-primary`,
                carouselIndex === 2 ? `bg-primary` : `bg-white`
              )}
              aria-hidden="true"
            />
          </div>

          <button
            onClick={() => {
              carouselIndex === CAROUSEL_ITEMS.length - 1
                ? getNextStep()
                : setCarouselIndex(carouselIndex + 1);
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}
    </>
  );
};
