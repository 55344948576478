import { useState } from "react";
import { Container } from "../../../components";

import BlueHart from "../../../assets/images/svg/BlueHart.svg";
import BlueGlasses from "../../../assets/images/svg/BlueGlasses.svg";
import BlueMobileEyeWithDistance from "../../../assets/images/svg/BlueMobileEyeWithDistance.svg";
import BlueGrid from "../../../assets/images/svg/BlueGrid.svg";

export const AmslerFormStart = ({
  getNextStep,
}: {
  getNextStep: () => void;
}) => {
  const [startCurrentStep, setStartCurrentStep] = useState(-1);

  const getNextInstruction = () => {
    setStartCurrentStep(startCurrentStep + 1);
  };

  return (
    <>
      {startCurrentStep === -1 && (
        <Container>
          <img
            src={BlueHart}
            width="50"
            height="55"
            alt="curecall blue heart logo"
            className="mx-auto"
          />

          <h1 className="text-center">
            Votre médecin souhaite que vous testiez votre vision
          </h1>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 0 && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <img src={BlueGlasses} alt="blue-glasses" className="mx-auto" />

          <p className="text-center">
            Si vous avez des lentilles de contact ou des lunettes, vous devez
            les <u>porter pendant le test</u>
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 1 && (
        <Container>
          <h1 className=" text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <img
            src={BlueMobileEyeWithDistance}
            alt="blue-mobile-eye"
            style={{ width: "14rem" }}
            className="mx-auto"
          />

          <p className="text-center">
            Tenez votre téléphone à une distance de lecture de
            <u>20 à 30 cm</u>
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 2 && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <img
            src={BlueGrid}
            alt="blue-mobile-eye"
            style={{ width: "7rem" }}
            className="mx-auto"
          />

          <ul>
            <li> La grille va s’afficher avec un point central, fixez-le.</li>
            <li>
              Touchez les zones qui vous semblent floues, déformées ou
              manquantes.
            </li>
            <li>
              Touchez à nouveau pour effacer une zone si vous vous êtes trompé.
            </li>
          </ul>

          <button
            className="mt-auto"
            onClick={() => {
              getNextStep();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}
    </>
  );
};
