import { useState, useEffect } from "react";
import { Container } from "../../../components";
import logo from "../../../assets/images/svg/LogoCurecall.svg";
import RightEye from "../../../assets/images/svg/BlueHandEye.svg";
import LeftEye from "../../../assets/images/svg/BlueEyeHand.svg";
import BlueEyes from "../../../assets/images/svg/BlueEyes.svg";
import { sessionStorageKey } from "../../../constants";

interface VisualAcuityResponse {
  size: number;
  value: string;
  isValid: boolean;
}

export const AccuityFormEnd = () => {
  const [rigthEyeNote, setRigthEyeNote] = useState<number | string>("0");
  const [leftEyeNote, setLeftEyeNote] = useState<number | string>("0");
  const [bothEyeNote, setBothEyeNote] = useState<number | string>("0");

  const getResult = (key: any) => {
    const SnellenToMonoyer: any = {
      "20/500": "1/25",
      "20/250": "1/12",
      "20/125": "1,6/10",
      "20/80": "2,5/10",
      "20/50": "4/10",
      "20/30": "6,3/10",
      "20/20": "10/10",
    };

    if (sessionStorage.getItem(key) === null) return "0/10";
    const responsesArray = JSON.parse(sessionStorage.getItem(key) || "");
    const minValidResponse: VisualAcuityResponse = responsesArray.find(
      (response: any) =>
        response.size ===
        Math.min(
          ...responsesArray
            .filter((response: any) => response.isValid)
            .map((response: any) => parseInt(response.size))
        )
    );
    if (!minValidResponse) return "0/10"; //or 0??

    return SnellenToMonoyer[minValidResponse.value];
  };

  const resetEyeNotes = () => {
    sessionStorage.removeItem(sessionStorageKey.visualAcuityRight);
    sessionStorage.removeItem(sessionStorageKey.visualAcuityLeft);
    sessionStorage.removeItem(sessionStorageKey.visualAcuityBoth);
  };

  useEffect(() => {
    setRigthEyeNote(getResult(sessionStorageKey.visualAcuityRight));
    setLeftEyeNote(getResult(sessionStorageKey.visualAcuityLeft));
    setBothEyeNote(getResult(sessionStorageKey.visualAcuityBoth));
    resetEyeNotes();
  }, []);

  return (
    <Container>
      <h1 className=" p-4 bg-cyan-300 text-center rounded-md">
        Questionnaire complété
      </h1>

      <h2 className="text-center">Votre vision de près</h2>

      <div className="flex items-center justify-between w-full p-2 border border-2 border-grey-400 rounded-lg [&>div]:w-1/2 [&>div]:text-center">
        <img src={RightEye} width="140" alt="right-eye-hand" />
        <div className=" flex flex-col gap-2">
          <u>Œil Droit :</u>
          <p>{rigthEyeNote}</p>
        </div>
      </div>

      <div className="flex items-center justify-between w-full p-2 border border-2 border-grey-400 rounded-lg [&>div]:w-1/2 [&>div]:text-center">
        <img src={LeftEye} width="140" alt="left-eye-hand" />
        <div className=" flex flex-col gap-2">
          <u>Œil Gauche :</u>
          <p>{leftEyeNote}</p>
        </div>
      </div>

      <div className="flex items-center justify-between w-full p-2 border border-2 border-grey-400 rounded-lg [&>div]:w-1/2 [&>div]:text-center">
        <img src={BlueEyes} width="140" alt="both-eyes" />
        <div className=" flex flex-col gap-2">
          <u>Les deux yeux :</u>
          <p>{bothEyeNote}</p>
        </div>
      </div>

      <img
        src={logo}
        width="89"
        height="81"
        alt="logo-curecall"
        className="mx-auto"
      />

      <p className="text-center">
        Les résultats de votre test ont été envoyés à votre médecin.
      </p>
    </Container>
  );
};
