import { useRef } from "react";
import CanvasDraw from "react-canvas-draw";
import AmslerGridBackground from "../../../assets/images/svg/AmslerGridBackground.svg";

export const AmslerTest = ({
  onSelectResponse,
}: {
  onSelectResponse: (param: any) => any;
}) => {
  const canvasRef = useRef<any>(null);

  return (
    <>
      <div className="flex flex-col">
        <CanvasDraw
          ref={canvasRef}
          brushRadius={7}
          lazyRadius={7}
          canvasWidth={288}
          canvasHeight={288}
          imgSrc={AmslerGridBackground}
          brushColor={"#000000"}
          hideInterface={true}
          className="relative mx-auto"
        />
      </div>

      <section className="flex flex-col justify-end gap-4 flex-grow z-10">
        <p className="text-center">Utilisez votre doigt pour dessiner</p>

        <button
          className="bg-white text-primary"
          onClick={() => {
            canvasRef.current.clear();
          }}
        >
          RECOMMENCER
        </button>

        <button
          className=""
          onClick={() => {
            onSelectResponse(canvasRef);
          }}
        >
          J'AI TERMINÉ
        </button>
      </section>
    </>
  );
};
