import { useEffect, useState } from "react";
import { PatientFormService } from "../services";
import { useParams, useNavigate } from "react-router";

import {
  QualityOfLifeDynamicForm,
  OpacityDynamicForm,
  AccuityDynamicForm,
  AmslerDynamicForm,
  QualityOfLifeNEIDynamicForm,
} from "../components";
import { ConsentDynamicForm } from "./forms/consent/ConsentDynamicForm";
import { MedicalHistoryDynamicForm } from "./forms/medicalHistory/MedicalHistoryDynamicForm";
import { QualityOfLifeHorusDynamicForm } from "./forms/qualityOfLifeHorus/QualityOfLifeHorusDynamicForm";
import { DropsComfortHorusDynamicForm } from "./forms/dropsConfortHorus/DropsComfortHorusDynamicForm";

const getDynamicForm = (formType: string) => {
  switch (formType) {
    case "QUALITY_OF_LIFE":
      return QualityOfLifeDynamicForm;
    case "CONTRAST_PERCEPTION":
      return OpacityDynamicForm;
    case "VISUAL_ACUITY":
      return AccuityDynamicForm;
    case "AMSLER_GRID":
      return AmslerDynamicForm;
    case "CONSENT":
      return ConsentDynamicForm;
    case "MEDICAL_HISTORY":
      return MedicalHistoryDynamicForm;
    case "QUALITY_OF_LIFE_NEI":
      return QualityOfLifeNEIDynamicForm;
    case "QUALITY_OF_LIFE_HORUS":
      return QualityOfLifeHorusDynamicForm;
    case "DROPS_COMFORT_HORUS":
      return DropsComfortHorusDynamicForm;
  }
};

const DynamicForm = () => {
  const { patientFormId } = useParams();
  const [currentStep, setCurrentStep] = useState(-1);
  const [resumeStep, setResumeStep] = useState(-1);
  const [form, setForm] = useState<any>();
  const [formId, setFormId] = useState<any>();
  const [patient, setPatient] = useState<any>();
  const [patientVariables, setPatientVariables] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const getPatientForm = () => {
      PatientFormService.getPatientFormsForCurrentUser(
        patientFormId || ""
      ).then((res: any) => {
        if (res.length === 0) navigate("/formNotAvailable");
        else {
          setPatientVariables(res[0].PatientVariables);
          setResumeStep(res[0].PatientVariables.length);
          setFormId(res[0].FormId);
          setPatient(res[0].Patient);
        }
      });
    };

    getPatientForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getFormById = async (formId: any) => {
      if (formId) {
        const { data }: any = await PatientFormService.getPatientFormByFormId(
          formId
        );

        const dataCopy = { ...data };
        dataCopy.FormVariables.sort(
          (var1: any, var2: any) => var1.position - var2.position
        );

        setForm(dataCopy);
      }
    };
    getFormById(formId);
  }, [formId]);

  const getNextStep = () => {
    if (resumeStep !== -1) {
      setCurrentStep(resumeStep);
      setResumeStep(-1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  if (!form) return null;

  const CurrentDynamicForm = getDynamicForm(form.type);

  if (!CurrentDynamicForm) return null;

  return (
    <CurrentDynamicForm
      formVariables={form.FormVariables}
      patientVariables={patientVariables}
      currentStep={currentStep}
      getNextStep={getNextStep}
      patient={patient}
      resumeStep={resumeStep}
    />
  );
};

export default DynamicForm;
