import { http } from "../../utils";
import LoginParams from "./LoginParams.type";
import AuthServiceType from "./AuthService.type";

export const AuthService: AuthServiceType = {
  login: async ({ hash, day, month, year, patientId }: LoginParams) => {
    return await http.post("/auth/patient", {
      birthdate: hash ? hash : `${day}/${month}/${year}`,
      patientId: patientId,
    });
  },
};
