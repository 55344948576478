import { Container } from "../../../components";
import logo from "../../../assets/images/svg/LogoCurecall.svg";

export const FormDropsComfortHorusEnd = () => (
  <Container className="text-center">
    <h1 className="p-4 bg-cyan-300 rounded-md ">Wypełniony kwestionariusz</h1>

    <img
      src={logo}
      width="89"
      height="81"
      alt="logo-curecall"
      className="mx-auto"
    />

    <h2>
      Dziękujemy za wypełnienie formularza dotyczącego wygody stosowania kropli do oczu.
      Życzymy miłego dnia!.
    </h2>
  </Container>
);
