import { Container } from "../../../components";
import logo from "../../../assets/images/svg/LogoCurecall.svg";

export const FormQualityOfLifeNeiEnd = () => (
  <Container className="text-center">
    <h1 className="p-4 bg-cyan-300 rounded-md ">Questionnaire terminé</h1>

    <img
      className="mx-auto"
      src={logo}
      width="89"
      height="81"
      alt="logo-curecall"
    />

    <h2>
      Le questionnaire est terminé, merci pour le temps que vous nous avez
      accordé, ces informations nous seront très utiles dans l'amélioration de
      votre prise en charge.
    </h2>

    <p>Votre cabinet d'ophtalmologie vous souhaite une très belle journée.</p>
  </Container>
);
