import { Container } from "../..";
import logo from "../../../assets/images/svg/LogoCurecall.svg";

interface SimpleEndPageTemplateArgs {
  endPageText: string;
  isBold?: boolean;
}

const SimpleEndPageTemplate = ({
  endPageText,
  isBold = false,
}: SimpleEndPageTemplateArgs): JSX.Element => {
  return (
    <Container className="text-center">
      <h1 className=" p-4 bg-cyan-300  mx-auto rounded-md">
        Questionnaire complété
      </h1>

      <img
        src={logo}
        width="89"
        height="81"
        alt="logo-curecall"
        className="mx-auto"
      />

      {isBold ? <h2>{endPageText}</h2> : <h2>{endPageText}</h2>}
    </Container>
  );
};

export default SimpleEndPageTemplate;
