import { useState } from "react";
import { DynamicFormProps } from "../types/dynamic-form";
import AcceptConsent from "./AcceptConsent";
import ConsentWrapper from "./ConsentWrapper";
import ConsentFormEnd from "./end";

export const ConsentDynamicForm = ({
  formVariables,
  currentStep,
  getNextStep,
  patient,
}: DynamicFormProps) => {
  const [fontScale, setFontScale] = useState(0);

  const increaseFontScale = () => {
    if (fontScale < 1.5) {
      setFontScale(fontScale + 0.1);
    }
  };

  const decreaseFontScale = () => {
    if (fontScale > -1.5) {
      setFontScale(fontScale - 0.1);
    }
  };

  return (
    <>
      <ConsentWrapper
        decreaseFontScale={decreaseFontScale}
        increaseFontScale={increaseFontScale}
        projectName={patient?.Project?.name || ""}
      >
        {currentStep === -1 && (
          <AcceptConsent
            getNextStep={getNextStep}
            fontScale={fontScale}
            patient={patient}
            variableId={formVariables[0].Variable.id}
          />
        )}

        {currentStep !== -1 && <ConsentFormEnd fontScale={fontScale} />}
      </ConsentWrapper>
    </>
  );
};
