import { useState } from "react";

import BlueEyeHand from "../../../assets/images/svg/BlueEyeHand.svg";
import BlueHandEye from "../../../assets/images/svg/BlueHandEye.svg";
import BlueEyes from "../../../assets/images/svg/BlueEyes.svg";

import { Container, EyeAcuity } from "../../../components";

interface StartPageProps {
  type: string;
  handLogo: string;
  alt: string;
  header_text: string;
  h3_text: string;
  span_text: string;
  percentage: number;
}

const getStartPageProps = (type: string) => {
  if (type === "visualAcuityL") {
    return {
      type,
      handLogo: BlueEyeHand,
      alt: "left-eye-hide",
      header_text: "ŒIL GAUCHE",
      h3_text: "Cachez votre œil droit",
      span_text: "Démarrer mon test de l’œil gauche !",
      percentage: 0.33,
    };
  }
  if (type === "visualAcuityR") {
    return {
      type,
      handLogo: BlueHandEye,
      alt: "right-eye-hide",
      header_text: "ŒIL DROIT",
      h3_text: "Cachez votre œil gauche",
      span_text: " Démarrer mon test de l’œil droit !",
      percentage: 0,
    };
  } else {
    return {
      type,
      handLogo: BlueEyes,
      alt: "both-eye",
      header_text: "LES DEUX YEUX",
      h3_text: "Enfin refaites le test avec vos deux yeux",
      span_text: "Démarrer mon test avec les deux yeux !",
      percentage: 0.66,
    };
  }
};

export const SetOfAcuityQuestions = ({
  variable,
  getNextStep,
}: {
  variable: any;
  getNextStep: () => void;
}) => {
  const name = variable.Variable.name;
  const startPageProps: StartPageProps = getStartPageProps(name);
  const [acuityQuestionsLocalStep, setAcuityQuestionsLocalStep] = useState(-1);

  const resetAcuityQuestionsLocalStep = () => {
    setAcuityQuestionsLocalStep(-1);
  };

  return (
    <Container>
      {acuityQuestionsLocalStep === -1 && (
        <>
          <progress value={startPageProps.percentage * 100} max="100" />

          <h1 className="text-center">{startPageProps.header_text}</h1>

          <img
            src={startPageProps.handLogo}
            alt={startPageProps.alt}
            style={{ width: "13rem" }}
            className="mx-auto"
          />

          <p className="text-center">{startPageProps.h3_text}</p>

          <button
            className="mt-auto"
            onClick={() => {
              setAcuityQuestionsLocalStep(acuityQuestionsLocalStep + 1);
            }}
          >
            {startPageProps.span_text}
          </button>
        </>
      )}

      {acuityQuestionsLocalStep === 0 && (
        <EyeAcuity
          startPageProps={startPageProps}
          variableId={variable.Variable.id}
          variableOptions={variable.Variable.VariableOptions}
          getNextStep={getNextStep}
          resetAcuityQuestionsLocalStep={resetAcuityQuestionsLocalStep}
        />
      )}
    </Container>
  );
};
