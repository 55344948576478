import { useState } from "react";

import BlueHart from "../../../assets/images/svg/BlueHart.svg";
import PatientReadingTable from "../../../assets/images/svg/PatientReadingTable.svg";
import BlueGlasses from "../../../assets/images/svg/BlueGlasses.svg";
import BlueMobileEye from "../../../assets/images/svg/BlueMobileEye.svg";

import MeasureInstructionPage from "../../CardMesurments/MeasureInstructionPage";
import MeasurementPage from "../../CardMesurments/MeasurementPage";
import { Container } from "../../../components";

export const AccuityFormStart = ({
  getNextStep,
}: {
  getNextStep: () => void;
}) => {
  const [startCurrentStep, setStartCurrentStep] = useState(-1);

  const getNextInstruction = () => {
    setStartCurrentStep(startCurrentStep + 1);
  };

  return (
    <>
      {startCurrentStep === -1 && (
        <Container>
          <img
            src={BlueHart}
            alt="blue-heart"
            style={{ width: "3rem" }}
            className="mx-auto"
          />

          <h1 className="text-center">
            Votre médecin souhaite que vous testiez votre vision
          </h1>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 0 && (
        <Container>
          <h1 className="text-center">
            Test de la vision
            <br />
            de près
          </h1>

          <img
            src={PatientReadingTable}
            style={{ width: "15rem", marginTop: "2rem" }}
            alt="patient-reading-table"
            className="mx-auto"
          />

          <p className="text-center">
            La vision de près correspond à la vision obtenue à longueur de bras
            <br />
            <u>
              <b>(environ 30-40 cm).</b>
            </u>
            <br />
            Elle correspond à la distance idéale pour la lecture, l’écriture et
            les travaux manuels.
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            J’AI COMPRIS
          </button>
        </Container>
      )}

      {startCurrentStep === 1 && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <img
            src={BlueGlasses}
            alt="blue-glasses"
            style={{
              marginTop: "4rem",
              marginBottom: "2rem",
              width: "13rem",
            }}
            className="mx-auto"
          />

          <p className="text-center">
            Si vous avez des lentilles de contact ou des lunettes, vous devez{" "}
            <b>
              <u>les porter pendant le test</u>
            </b>
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 2 && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <img
            src={BlueMobileEye}
            className="mx-auto"
            alt="blue-mobile-eye"
            style={{
              marginTop: "4rem",
              marginBottom: "2rem",
              width: "14rem",
            }}
          />

          <p className="text-center">
            Tenez votre téléphone à une distance de lecture confortable et
            constante durant tout le test
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextInstruction();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {startCurrentStep === 3 && (
        <MeasureInstructionPage getNextInstruction={getNextInstruction} />
      )}

      {startCurrentStep === 4 && <MeasurementPage getNextStep={getNextStep} />}
    </>
  );
};
