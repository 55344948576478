import LoginParamsType, { AutoLoginParamsType } from "./LoginParams.type";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useMemo,
} from "react";
import AuthContextType from "./AuthContext.type";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { AuthService } from "../../services";

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  // change page resets error state
  useEffect(() => {
    if (error) setError(null);
  }, [error, location.pathname]);

  useEffect(() => {
    setToken(
      localStorage.getItem("access_token") ||
        sessionStorage.getItem("access_token") ||
        undefined
    );

    setLoadingInitial(false);
  }, []);

  const login = async ({
    day,
    month,
    year,
    remember,
    patientId,
    patientFormId,
  }: LoginParamsType) => {
    setLoading(true);
    cleanLocalStorage();
    const { data }: any = await AuthService.login({
      day,
      month,
      year,
      remember,
      patientId,
    });

    if (remember) {
      localStorage.setItem("access_token", data.access_token);
    } else {
      sessionStorage.setItem("access_token", data.access_token);
    }
    setToken(data.access_token);
    localStorage.setItem("patientId", patientId || "");
    localStorage.setItem("patientFormId", patientFormId || "");

    navigate(`/form/${patientFormId}`);

    setLoading(false);
  };

  const autologin = async ({
    hash,
    patientId,
    patientFormId,
  }: AutoLoginParamsType) => {
    setLoading(true);
    cleanLocalStorage();
    const { data }: any = await AuthService.login({
      hash,
      patientId,
    });

    setToken(data.access_token);
    sessionStorage.setItem("access_token", data.access_token);
    localStorage.setItem("patientId", patientId || "");
    localStorage.setItem("patientFormId", patientFormId || "");

    navigate(`/form/${patientFormId}`);

    setLoading(false);
  };

  const cleanLocalStorage=()=>{
    localStorage.clear();
    sessionStorage.clear();
  }
  const logout = () => {
    cleanLocalStorage();
    setToken(undefined);
    //   history.push("/login");
  };

  const redirectLoggedIn = (patientFormId: string) => {
    if (token) navigate(`/form/${patientFormId}`);
  };

  const memoedValue = useMemo(
    () => ({
      token,
      loading,
      error,
      login,
      autologin,
      logout,
      redirectLoggedIn,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, loading, error]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
