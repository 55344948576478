import { useState } from "react";

import BlueHart from "../../../assets/images/svg/BlueHart.svg";

import { Container } from "../../../components";

export const FormQualityOfLifeStart = ({
  getNextStep,
}: {
  getNextStep: () => void;
}) => {
  const [currentStepStart, setCurrentStepStart] = useState(-1);

  return (
    <>
      {currentStepStart === -1 && (
        <Container className="text-center">
          <img
            src={BlueHart}
            width="50"
            height="55"
            alt="blue-heart"
            className="mx-auto"
          />

          <h1>
            Votre médecin souhaite vous poser quelques questions sur les
            difficultés que vous rencontrez au quotidien
          </h1>

          <button
            className="mt-auto"
            onClick={() => {
              setCurrentStepStart(currentStepStart + 1);
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {currentStepStart === 0 && (
        <Container className="text-center">
          <h1>
            Instructions pour
            <br />
            le test
          </h1>

          <p>
            Vos réponses sont précieuses pour votre médecin afin de
            personnaliser votre suivi.
          </p>

          <p>
            Choisissez la réponse qui correspond le mieux à votre vécu et
            ressenti, il n’y a pas de bonne ou mauvaise réponse.
          </p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextStep();
            }}
          >
            COMMENCER
          </button>
        </Container>
      )}
    </>
  );
};
