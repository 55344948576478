import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const Container = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <main
      className={twMerge(
        "bg-white min-h-[100svh] overflow-y-scroll overflow-x-hidden flex flex-col items p-8 gap-8",
        className
      )}
    >
      {children}
    </main>
  );
};
