import {
  FormQualityOfLifeStart,
  FormQualityOfLifeEnd,
  SingleQuestion,
} from "../../../components";
import { DynamicFormProps } from "../types/dynamic-form";

export const QualityOfLifeDynamicForm = ({
  formVariables,
  currentStep,
  getNextStep,
}: DynamicFormProps) => {
  const formVariablesLength = formVariables?.length;
  return (
    <>
      {currentStep === -1 && (
        <FormQualityOfLifeStart getNextStep={getNextStep} />
      )}
      {currentStep !== -1 && currentStep !== formVariablesLength && (
        <SingleQuestion
          formVariable={formVariables ? formVariables[currentStep] : undefined}
          getNextStep={getNextStep}
          currentStep={currentStep}
          formVariablesLength={formVariablesLength}
        />
      )}
      {currentStep === formVariablesLength && <FormQualityOfLifeEnd />}
    </>
  );
};
