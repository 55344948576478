import { useState } from "react";

import BlueHart from "../../../assets/images/svg/BlueHart.svg";

import { Container } from "../../../components";

export const FormQualityOfLifeNeiStart = ({
  currentStep,
  getNextStep,
  previousStep,
  uiMessage,
}: {
  currentStep: number;
  getNextStep: () => void;
  previousStep: () => void;
  uiMessage?: {
    title?: string;
    text?: string;
  };
}) => {
  const [currentStepStart, setCurrentStepStart] = useState(currentStep);
  return (
    <>
      {currentStepStart === -1 && !uiMessage && (
        <Container>
          <img
            src={BlueHart}
            width="50"
            height="55"
            alt="blue-heart"
            className="mx-auto"
          />

          <h1 className="text-center">
            Questionnaire sur le fonctionnement de votre vue
          </h1>

          <p>
            Le questionnaire suivant contient des déclarations sur des problèmes
            liés à votre vision ou des sentiments que vous éprouvez à l'égard de
            votre état de vision. Après chaque question, veuillez choisir la
            réponse qui décrit le mieux votre situation.
          </p>

          <p>
            Veuillez répondre à toutes les questions comme si vous portiez vos
            lunettes ou vos lentilles de contact (le cas échéant).
          </p>

          <p>
            Veuillez prendre tout le temps nécessaire pour répondre à chaque
            question.
          </p>

          <h2>Toutes vos réponses sont confidentielles.</h2>

          <p>
            Pour que cette enquête puisse améliorer nos connaissances sur les
            problèmes de vision et leur incidence sur votre qualité de vie, vos
            réponses doivent être aussi précises que possible.
          </p>

          <h3>
            N'oubliez pas que si vous portez des lunettes ou des lentilles de
            contact, veuillez répondre à toutes les questions suivantes comme si
            vous les portiez.
          </h3>

          <button
            onClick={() => {
              setCurrentStepStart(currentStepStart + 1);
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}

      {currentStepStart === 0 && !uiMessage && (
        <Container>
          <h1 className="text-center">
            Instructions pour
            <br />
            le test
          </h1>

          <ol>
            <li>
              Nous vous demandons d'essayer de remplir ce questionnaire par
              vous-même. Mais si vous pensez avoir besoin d'aide, *n'hésitez pas
              à vous{" "}
              <u>adresser à une personne de confiance de votre entourage.</u>
            </li>
            <li>Veuillez répondre à toutes les questions posées.</li>
            <li>
              Répondez aux questions en touchant la réponse sur votre téléphone.
            </li>
            <li>
              Si vous ne savez pas quelle réponse choisir, choisissez celle qui
              se rapproche le plus de votre situation.
            </li>
            <li>
              Remplissez ce questionnaire dans un endroit calme et bien éclairé,
              il sera envoyé automatiquement à votre cabinet d'ophtalmologie une
              fois terminé.
            </li>
          </ol>

          <button
            onClick={() => {
              getNextStep();
            }}
          >
            COMMENCER
          </button>
        </Container>
      )}
      {uiMessage && (
        <Container>
          <u className="text-primary" onClick={() => previousStep()}>
            Page Précédente
          </u>

          <img
            src={BlueHart}
            width="50"
            height="55"
            alt="blue-heart"
            className="mx-auto"
          />

          <h1 className="text-center">{uiMessage?.title}</h1>

          <p className="text-center">{uiMessage?.text}</p>

          <button
            className="mt-auto"
            onClick={() => {
              getNextStep();
            }}
          >
            CONTINUER
          </button>
        </Container>
      )}
    </>
  );
};
