import { colors } from "../constants";
import logo from "../assets/images/svg/LogoCurecall.svg";

const ViewOnMobilePage = (): JSX.Element => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        width="89"
        height="81"
        alt="logo-curecall"
        style={{ marginTop: "10vw" }}
      />

      <h2
        style={{
          textAlign: "center",
          marginTop: "1.6rem",
          paddingRight: "2.5rem",
          paddingLeft: "2.5rem",
          color: colors.BLUE,
          fontSize: "1rem",
          fontWeight: "bold",
          fontFamily: "Luciole Bold",
        }}
      >
        Veuillez Consulter cette page sur mobile.
      </h2>
    </div>
  );
};

export default ViewOnMobilePage;
